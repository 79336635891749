import { buttonFlexContainer } from "../constants/classnames";
import { ButtonBack } from "./buttonBack";
import { ButtonNext } from "./buttonNext";

export const ButtonBackNext = ({ handleBackClick, handleNextClick }) => {
  return (
    <div className={buttonFlexContainer}>
      <ButtonBack handleClick={handleBackClick} />
      <ButtonNext handleClick={handleNextClick} />
    </div>
  );
};
