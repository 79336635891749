import { container2, errorClass, questionMain, textareaClass } from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { useForm } from "react-hook-form";
import { LIFE_IMPROVEMENT } from "./overview";
import { handleError } from "../../../utils/handleError";
import { handleSave } from "./handleSave";
import { useEffect, useState } from "react";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";

// additional
const DETAILS = "details";

export function LifeImprovement() {
  const navigate = useNavigate();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue } = useForm({
    mode: "all",
    defaultValues: {
      [LIFE_IMPROVEMENT]: "",
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(LIFE_IMPROVEMENT)
      .then((response) => {
        response.forEach((row) => {
          if (row.additional_json) {
            if (row.additional_json[DETAILS]) {
              setValue(DETAILS, row.additional_json[DETAILS]);
            }
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  const handlePreSave = (path) => {
    const values = getValues();
    if (values[DETAILS] !== "") {
      // eslint-disable-next-line no-console
      console.log("values", values);
      setGenericErrorMessage("");
      const newDetails = [
        {
          selection: LIFE_IMPROVEMENT,
          additional_json: { details: values[DETAILS] },
        },
      ];
      handleSave(LIFE_IMPROVEMENT, newDetails, navigate, path);
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/housing";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/additional-info";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="heading">
        <div className={container2}>
          <div className={questionMain}>
            How have you improved your life since your involvement with the justice system?
          </div>
          <textarea
            type="text"
            name={DETAILS}
            id={DETAILS}
            className={textareaClass}
            rows="10"
            {...register(DETAILS)}
          ></textarea>
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext handleBackClick={handleBackClick} handleNextClick={handleNextClick} />
        </div>
      </section>
    </>
  );
}
