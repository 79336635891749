import { additionalAmount, NO_INCOME, personTo150 } from "./income/incomeRanges2024.js";

export const moreThan12 = "more than 12"; // keep lower case
export const onlyMe = "only me"; // keep lower case

export const getFeeWaiverEligibility = (number_of_dependents, income_range, government_benefit) => {
  // https://docs.google.com/spreadsheets/d/1jmMVV8c5FqsFxXhz4I7VlQq6mtsXsrIuqgJE1LgSyKo/edit?gid=0#gid=0
  if ((government_benefit && government_benefit != "no") || income_range === NO_INCOME) {
    return true;
  }
  const income_top = parseInt(income_range.split("$").pop().replace(",", ""));
  let number = 1;
  if (number_of_dependents !== onlyMe && number_of_dependents !== moreThan12) {
    number = parseInt(number_of_dependents);
  } else if (number_of_dependents === moreThan12) {
    // todo handle more
    number = 13;
  }
  if (number <= 3) {
    return income_top <= personTo150[3];
  } else if (number <= 12) {
    return income_top <= personTo150[number];
  } else {
    const additionalDeps = number - 12;
    return income_top <= additionalDeps * additionalAmount + personTo150[12];
  }
};
