import { checkboxLabel } from "../constants/classnames";

export const Checkbox = ({ value, currentValues, setCurrentValues }) => {
  const handleChange = (e) => {
    const currentValue = e.target.value;
    const infoList = [...currentValues];
    if (e.target.checked) {
      infoList.push(currentValue);
    } else {
      const index = infoList.indexOf(currentValue);
      infoList.splice(index, 1);
    }
    setCurrentValues(infoList);
  };

  return (
    <div className="relative mt-4 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
      <input
        type="checkbox"
        checked={currentValues.includes(value)}
        name={`${name}Checkbox`}
        id={`${name}-${value}`}
        className="ml-4 cursor-pointer appearance-none rounded text-rasapink checked:bg-rasapink focus:ring-rasapink"
        value={value}
        aria-invalid="false"
        aria-describedby={`${name}-error`}
        onChange={handleChange}
      />
      <label htmlFor={`${name}-${value}`} className={checkboxLabel}>
        {value}
      </label>
    </div>
  );
};
