import RasaLogo from "@images/rasa-logo.svg";
import { Link } from "react-router-dom";

export const App = () => {
  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          {/*<p className="mb-5 text-center text-3xl font-bold text-gray-700">welcome!</p>*/}
          <Link to={"/register"}>
            <button
              type="button"
              className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
            >
              check eligibility
            </button>
          </Link>
          <Link to="/login">
            <button
              type="button"
              className="my-4 w-full rounded-full bg-white px-24 py-4 font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-rasapink hover:shadow-xl"
            >
              login
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};
