import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { SelectStyled } from "../../../../components/Select";
import { industryOptions } from "./options";
import { Error } from "../../../../components/error";
import { useState } from "react";
import { MANDATORY_SURVEY, OPTIONAL_SURVEY } from "../../../../constants/surveyTypes";
import { updatePerson } from "../../../../api/secureApi";
import { adjustState } from "./utils";
import { handleError } from "../../../../utils/handleError";
import { container3 } from "../../../../constants/classnames";

export function IndustryWant() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const newState = {
      ...state,
      industry_want: data.industry_want.value,
      industry_want_other: data.industry_want_other,
    };
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    let currentOptionalSurvey = state.surveys.find((survey) => survey.type === OPTIONAL_SURVEY);
    if (currentOptionalSurvey) {
      currentOptionalSurvey.answers.industry_want = data.industry_want.value;
      currentOptionalSurvey.answers.industry_want_other = data.industry_want_other;
    } else {
      currentOptionalSurvey = {
        type: OPTIONAL_SURVEY,
        answers: {
          industry_want: data.industry_want.value,
          industry_want_other: data.industry_want_other,
        },
      };
    }
    const stateToSubmit = {
      profile_id: newState.profile_id,
      surveys: [currentOptionalSurvey, currentMandatorySurvey],
    };
    adjustState(state, stateToSubmit);
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in industry_current", stateToSubmit);

    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in industry_current", response);
        navigate("/register/job-opportunities", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in industry_current", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">
            4. If you knew your record was not going to be a barrier, what industry would you want
            to work in?
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={container3}>
            <div className="relative mb-4 mt-2 rounded-md shadow-sm">
              <SelectStyled
                id="industry_want"
                name="industry_want"
                options={industryOptions}
                register={register}
                setShowOtherInput={setShowOtherInput}
                {...register("industry_want", { required: true })}
              />
              <Error errors={errors} name="industry_want" />
            </div>
            {showOtherInput && (
              <div className="relative mb-4 mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="industry_want_other"
                  id="industry_want_other"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  placeholder="other industry"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="industry_want_other-error"
                  {...register("industry_want_other", { required: true })}
                />
                <Error errors={errors} name="industry_want_other" />
              </div>
            )}
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
