import RasaLogo from "@images/rasa-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { WhyModal } from "@components";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { updatePerson } from "../../../../api/secureApi";
import { MANDATORY_SURVEY } from "../../../../constants/surveyTypes";
import { handleError } from "../../../../utils/handleError";
import {
  errorUnderInput,
  inputLabel,
  inputWrapper,
  questionMain2,
} from "../../../../constants/classnames";

export function Ethnicity() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [openWhy, setOpenWhy] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const newState = { ...state, ...data };

    const stateToSubmit = {
      profile_id: newState.profile_id,
      surveys: [
        {
          type: MANDATORY_SURVEY,
          answers: data,
        },
      ],
    };

    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in ethn", response);
        // todo: get profileId from response, add it to state before nav
        navigate("/register/created", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in ethn", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };
  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          {/*logo*/}
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="heading">
        <div className="container mx-auto mt-6 flex max-w-3xl flex-col px-4">
          <div className="flex flex-row">
            <h2 className={questionMain2}>
              what is your race and{" "}
              <span className="whitespace-nowrap">
                ethnicity?
                <InformationCircleIcon
                  className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
                  onClick={() => setOpenWhy(true)}
                />
              </span>
            </h2>
          </div>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-10 flex max-w-3xl flex-col px-4">
            {/*  race */}
            <div className="pb-4">
              <label htmlFor="race" className={inputLabel}>
                race *
              </label>
              <div className={inputWrapper}>
                <select
                  id="race"
                  name="race"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  onChangeCapture={(e) => {
                    if (e.target.value === "other") {
                      setShowOtherInput(true);
                    } else {
                      setShowOtherInput(false);
                    }
                  }}
                  {...register("race", { required: true })}
                >
                  <option value="" disabled />
                  <option value="american-indian">American Indian or Alaska Native</option>
                  <option value="asian">Asian</option>
                  <option value="african-american">Black</option>
                  <option value="pacific-islander">
                    Native Hawaiian or other Pacific Islander
                  </option>
                  <option value="white">White</option>
                  <option value="other">Other (please specify below)</option>
                  <option value="undisclosed">I prefer not to say</option>
                </select>
              </div>
              {errors.race && (
                <div className={errorUnderInput} id="fname-error">
                  Race selection is required.
                </div>
              )}
              {showOtherInput && (
                <div className="py-4">
                  <label htmlFor="race_other" className={inputLabel}>
                    other race *
                  </label>
                  <div className={inputWrapper}>
                    <input
                      type="text"
                      name="race_other"
                      id="race_other"
                      className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                      defaultValue=""
                      aria-invalid="false"
                      aria-describedby="race_other-error"
                      {...register("race_other", { required: true })}
                    />
                  </div>
                  {errors.race_other && (
                    <div className={errorUnderInput} id="race_other-error">
                      Answer is required.
                    </div>
                  )}
                </div>
              )}
            </div>
            {/*  ethnicity */}
            <div className="pb-4">
              <label htmlFor="ethnicity" className={inputLabel}>
                ethnicity *
              </label>
              <div className={inputWrapper}>
                <select
                  id="ethnicity"
                  name="ethnicity"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  {...register("ethnicity", { required: true })}
                >
                  <option value="" disabled />
                  <option value="hispanic">Hispanic or Latino</option>
                  <option value="not-hispanic">Not Hispanic or Latino</option>
                  <option value="undisclosed">I prefer not to say</option>
                </select>
              </div>
              {errors.ethnicity && (
                <div className={errorUnderInput} id="ethnicity-error">
                  Ethnicity selection is required.
                </div>
              )}
            </div>
            <WhyModal
              open={openWhy}
              setOpen={setOpenWhy}
              answer="At Rasa, we care about racial and ethnic equality.  This data helps us measure our impact."
            />
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
