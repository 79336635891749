import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Error } from "../../../../components/error";
import { Checkbox } from "../../../../components/checkbox";
import { updatePerson } from "../../../../api/secureApi";
import { MANDATORY_SURVEY } from "../../../../constants/surveyTypes";
import { handleError } from "../../../../utils/handleError";
// Chandler, Gilbert, Maricopa, Mesa, Paradise Valley, Prescott, Tempe, Yavapai

export function ArizonaData() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [currentValues, setCurrentValues] = useState([]);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = () => {
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    // eslint-disable-next-line no-console
    console.log("currentMandatorySurvey", currentMandatorySurvey);
    const stateToSubmit = {
      profile_id: state.profile_id,
      surveys: [
        {
          type: MANDATORY_SURVEY,
          answers: {
            race: currentMandatorySurvey.answers.race,
            race_other: currentMandatorySurvey.answers.race_other || "", // optional field
            ethnicity: currentMandatorySurvey.answers.ethnicity,
            open_cases: currentMandatorySurvey.answers.open_cases,
            on_probation_or_parole: currentMandatorySurvey.answers.on_probation_or_parole,
            other_convictions: currentMandatorySurvey.answers.other_convictions,
            other_convictions_list: currentMandatorySurvey.answers.other_convictions_list || [], // optional field
            expunged_before: currentMandatorySurvey.answers.expunged_before,
            expunged_before_story: currentMandatorySurvey.answers.expunged_before_story || "", // optional field
            number_of_dependents: currentMandatorySurvey.answers.number_of_dependents,
            income_range: currentMandatorySurvey.answers.income_range,
            income_range_fpl: currentMandatorySurvey.answers.income_range_fpl,
            arizona_counties: [...currentValues],
          },
        },
      ],
    };
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in az data", stateToSubmit);

    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in az data", response);
        navigate("/register/court-data", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in expunged", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">
            Our Arizona dataset is based on available court records
          </h2>
        </div>
      </section>
      <section id="bodyText">
        <div className="container mx-auto mt-6 flex max-w-3xl flex-col px-4">
          <div className="mb-6">
            This current <b>Arizona</b> dataset does NOT contain records from the following courts:
            <div className="ml-10 mt-1">
              <ul className="list-disc">
                <li>
                  Justice and Municipal level courts (usually minor misdemeanors) in Maricopa,
                  Yavapai, Chandler, Gilbert, Mesa, Paradise Valley, Prescott and Tempe
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-6 mb-10 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <label htmlFor="referral" className="block font-bold leading-6 text-gray-900">
                If you have records in these counties, select them here (please select all that
                apply):
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <Checkbox
                  value="Chandler"
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                <Checkbox
                  value="Gilbert"
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                <Checkbox
                  value="Maricopa"
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                <Checkbox
                  value="Mesa"
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                <Checkbox
                  value="Paradise Valley"
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                <Checkbox
                  value="Prescott"
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                <Checkbox
                  value="Tempe"
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                <Checkbox
                  value="Yavapai"
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
              </div>
              <Error errors={errors} name="recordsInCounties" />
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 text-sm font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
