import Select from "react-tailwindcss-select";
import { useState } from "react";

export const SelectMessageUs = ({
  id,
  name,
  options,
  register,
  setShowOtherInput = (v) => ({ v }),
}) => {
  const [currentValue, setCurrentValue] = useState(null);
  const registeredName = register(name);

  const handleChange = (obj) => {
    if (obj && obj.value && obj.value.includes("(please specify below)")) {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
    setCurrentValue(obj);
    registeredName.onChange({
      target: {
        name: name,
        value: obj,
      },
    });
  };

  return (
    <Select
      id={id}
      name={name}
      value={currentValue}
      onChange={handleChange}
      options={options}
      isMultiple={false}
      // isClearable
      classNames={{
        menuButton: () =>
          "flex text-sm px-2 py-2 border border-black rounded shadow-sm transition-all duration-300 focus:outline-none" +
          " bg-white hover:border-gray-400 focus:border-pink-500 focus:ring focus:ring-pink-500/20 [&_p]:whitespace-break-spaces",
        listItem: () =>
          "block transition duration-200 px-2 py-2 cursor-pointer select-none text-wrap rounded" +
          " break-normal text-gray-700 hover:bg-pink-100 hover:text-rasapink",
        tagItem: () => "flex text-sm bg-purple p-1 mr-1 border border-gray-200 rounded [&_svg]:w-4",
      }}
    />
  );
};
