import {
  animate,
  container2,
  errorClass,
  inputWrapper,
  pinkSection,
  question,
  questionMain,
  textareaClassPink,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "../../../components/checkbox";
import { useEffect, useState } from "react";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { BARRIERS } from "./overview";
import { handleDetailsChange, handleSave, setInitialValues } from "./handleSave";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";

// selections
const DENIED_JOBS = "denied jobs";
const DENIED_HOUSING = "denied housing";
const DENIED_EDUCATION = "denied education opportunity";
const DENIED_VOLUNTEER_WORK = "denied volunteer work";

// additional details
const DETAIL = "detail";

export function Barriers() {
  const navigate = useNavigate();
  const [currentValues, setCurrentValues] = useState([]);
  const [details, setDetails] = useState([]);
  const [showHousingDetailInput, setShowHousingDetailInput] = useState(false);
  const [showEducationDetailInput, setShowEducationDetailInput] = useState(false);
  const [showJobDetailInput, setShowJobDetailInput] = useState(false);
  const [showVolunteerDetailInput, setShowVolunteerDetailInput] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setInitialValues(BARRIERS, setCurrentValues, setDetails, navigate, setGenericErrorMessage);
  }, [navigate]);

  useEffect(() => {
    // if selection has additional questions, show them
    if (currentValues.includes(DENIED_JOBS)) {
      setShowJobDetailInput(true);
    } else {
      setShowJobDetailInput(false);
    }
    if (currentValues.includes(DENIED_HOUSING)) {
      setShowHousingDetailInput(true);
    } else {
      setShowHousingDetailInput(false);
    }
    if (currentValues.includes(DENIED_EDUCATION)) {
      setShowEducationDetailInput(true);
    } else {
      setShowEducationDetailInput(false);
    }
    if (currentValues.includes(DENIED_VOLUNTEER_WORK)) {
      setShowVolunteerDetailInput(true);
    } else {
      setShowVolunteerDetailInput(false);
    }
  }, [currentValues]);

  const handlePreSave = (path) => {
    if (currentValues.length > 0) {
      if (details.length === 0) {
        setGenericErrorMessage("Please give brief details.");
      } else {
        setGenericErrorMessage("");
        //   loop through details, if doesn't exist in values then delete it in details
        const currentDetails = [...details];
        const newDetails = currentDetails.filter((obj) => currentValues.includes(obj.selection));
        setDetails(newDetails);
        // eslint-disable-next-line no-console
        console.log("details to save", newDetails);
        handleSave(BARRIERS, newDetails, navigate, path);
      }
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/probation";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/reasons";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section>
        <form>
          <div className={container2}>
            <h2 className={questionMain}>How has having this criminal record been a barrier?</h2>
            <div>
              <div className={showJobDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={DENIED_JOBS}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showJobDetailInput && (
                  <div className={animate}>
                    <div className={question}>Please give brief details</div>
                    <div className={inputWrapper}>
                      <textarea
                        placeholder="not hired or let go due to background check"
                        type="text"
                        name={DETAIL}
                        id={DETAIL}
                        className={textareaClassPink}
                        rows="3"
                        onChange={(e) =>
                          handleDetailsChange(e, DENIED_JOBS, DETAIL, details, setDetails)
                        }
                        defaultValue={
                          details.find(
                            (item) =>
                              item.selection === DENIED_JOBS && item.additional_json[DETAIL],
                          )?.additional_json[DETAIL] || ""
                        }
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div>
              <div className={showHousingDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={DENIED_HOUSING}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showHousingDetailInput && (
                  <div className={animate}>
                    <div className={question}>Please give brief details</div>
                    <div className={inputWrapper}>
                      <textarea
                        placeholder="8 rental applications denied after background check"
                        type="text"
                        name={DETAIL}
                        id={DETAIL}
                        className={textareaClassPink}
                        rows="3"
                        onChange={(e) =>
                          handleDetailsChange(e, DENIED_HOUSING, DETAIL, details, setDetails)
                        }
                        defaultValue={
                          details.find(
                            (item) =>
                              item.selection === DENIED_HOUSING && item.additional_json[DETAIL],
                          )?.additional_json[DETAIL] || ""
                        }
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div>
              <div className={showEducationDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={DENIED_EDUCATION}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showEducationDetailInput && (
                  <div className={animate}>
                    <div className={question}>Please give brief details</div>
                    <div className={inputWrapper}>
                      <textarea
                        placeholder="could not get into Nursing program with record"
                        type="text"
                        name={DETAIL}
                        id={DETAIL}
                        className={textareaClassPink}
                        rows="3"
                        onChange={(e) =>
                          handleDetailsChange(e, DENIED_EDUCATION, DETAIL, details, setDetails)
                        }
                        defaultValue={
                          details.find(
                            (item) =>
                              item.selection === DENIED_EDUCATION && item.additional_json[DETAIL],
                          )?.additional_json[DETAIL] || ""
                        }
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div>
              <div className={showVolunteerDetailInput ? pinkSection : ""}>
                <Checkbox
                  value={DENIED_VOLUNTEER_WORK}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                />
                {showVolunteerDetailInput && (
                  <div className={animate}>
                    <div className={question}>Please give brief details</div>
                    <div className={inputWrapper}>
                      <textarea
                        placeholder="I'm not allowed to chaperone my child's class field trips"
                        type="text"
                        name={DETAIL}
                        id={DETAIL}
                        className={textareaClassPink}
                        rows="3"
                        onChange={(e) =>
                          handleDetailsChange(e, DENIED_VOLUNTEER_WORK, DETAIL, details, setDetails)
                        }
                        defaultValue={
                          details.find(
                            (item) =>
                              item.selection === DENIED_VOLUNTEER_WORK &&
                              item.additional_json[DETAIL],
                          )?.additional_json[DETAIL] || ""
                        }
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {genericErrorMessage && (
              <div className={errorClass} id="error">
                {genericErrorMessage}
              </div>
            )}
            <ButtonBackNext handleBackClick={handleBackClick} handleNextClick={handleNextClick} />
          </div>
        </form>
      </section>
    </>
  );
}
