import RasaLogo from "@images/rasa-logo.svg";
import { Link } from "react-router-dom";
import { logo } from "../../../constants/classnames";

export const Header = ({ handleOverviewClick, handleLogoutClick }) => {
  return (
    <>
      <div className="flex justify-between">
        <div>
          <Link onClick={handleOverviewClick}>
            <div className="mt-4 px-6 text-base tracking-tighter text-rasapink">overview</div>
          </Link>
        </div>
        <div>
          <Link onClick={handleLogoutClick}>
            <div className="mt-4 px-6 text-base tracking-tighter text-rasapink">log out</div>
          </Link>
        </div>
      </div>
      <div className={logo}>
        <RasaLogo className="h-7" />
      </div>
    </>
  );
};
