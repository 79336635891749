import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { WhyModal } from "@components";
import { SelectLocal } from "../../../../../components/SelectLocal";
import { updatePerson } from "../../../../../api/secureApi";
import { MANDATORY_SURVEY } from "../../../../../constants/surveyTypes";
import { handleError } from "../../../../../utils/handleError";
import { AZ, STATE_LIST } from "../../../../../constants/usStates";
import { errorUnderInput, questionMain3 } from "../../../../../constants/classnames";

import {
  incomeRangeEightPersonOptions,
  incomeRangeElevenPersonOptions,
  incomeRangeFivePersonOptions,
  incomeRangeFourPersonOptions,
  incomeRangeNinePersonOptions,
  incomeRangeOnePersonOptions,
  incomeRangeSevenPersonOptions,
  incomeRangeSixPersonOptions,
  incomeRangeTenPersonOptions,
  incomeRangeThreePersonOptions,
  incomeRangeTwelvePersonOptions,
  incomeRangeTwoPersonOptions,
} from "./incomeRanges2024";
import { moreThan12, onlyMe } from "../utils.js";

const dependentOptions = [
  { value: onlyMe, label: onlyMe },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: moreThan12, label: moreThan12 },
];

const personToRange = {
  1: incomeRangeOnePersonOptions,
  2: incomeRangeTwoPersonOptions,
  3: incomeRangeThreePersonOptions,
  4: incomeRangeFourPersonOptions,
  5: incomeRangeFivePersonOptions,
  6: incomeRangeSixPersonOptions,
  7: incomeRangeSevenPersonOptions,
  8: incomeRangeEightPersonOptions,
  9: incomeRangeNinePersonOptions,
  10: incomeRangeTenPersonOptions,
  11: incomeRangeElevenPersonOptions,
  12: incomeRangeTwelvePersonOptions,
};

export function Income() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // eslint-disable-next-line no-console
  console.log("state in income", state);
  const [openWhy, setOpenWhy] = useState(false);
  const [options, setOptions] = useState(personToRange[1]);
  const [numberOfDependents, setNumberOfDependents] = useState(null);
  const [incomeRange, setIncomeRange] = useState(null);
  // const [incomeForMoreThan12, setIncomeForMoreThan12] = useState("");
  const [numberOfDependentsError, setNumberOfDependentsError] = useState(false);
  const [incomeRangeError, setIncomeRangeError] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { handleSubmit } = useForm({ mode: "all" });

  // 2375
  const onSubmit = () => {
    if (!numberOfDependents) {
      setNumberOfDependentsError(true);
    } else if (!incomeRange) {
      setIncomeRangeError(true);
    } else {
      const currentMandatorySurvey = state.surveys.find(
        (survey) => survey.type === MANDATORY_SURVEY,
      );
      const stateToSubmit = {
        profile_id: state.profile_id,
        surveys: [
          {
            type: MANDATORY_SURVEY,
            answers: {
              race: currentMandatorySurvey.answers.race,
              race_other: currentMandatorySurvey.answers.race_other || "", // optional field
              ethnicity: currentMandatorySurvey.answers.ethnicity,
              open_cases: currentMandatorySurvey.answers.open_cases,
              on_probation_or_parole: currentMandatorySurvey.answers.on_probation_or_parole,
              other_convictions: currentMandatorySurvey.answers.other_convictions,
              other_convictions_list: currentMandatorySurvey.answers.other_convictions_list || [], // optional field
              expunged_before: currentMandatorySurvey.answers.expunged_before,
              expunged_before_story: currentMandatorySurvey.answers.expunged_before_story || "", // optional field
              number_of_dependents: numberOfDependents.value,
              income_range: incomeRange.value ? incomeRange.value : incomeRange,
              income_range_fpl: incomeRange.info ? incomeRange.info : incomeRange,
            },
          },
        ],
      };
      // eslint-disable-next-line no-console
      console.log("stateToSubmit in income", stateToSubmit);
      updatePerson(stateToSubmit)
        .then((response) => {
          // eslint-disable-next-line no-console
          console.log("response in income", response);
          if (response.us_state === AZ) {
            navigate("/register/arizona-data", { state: response });
          } else {
            navigate("/register/government-benefits", { state: response });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error in expunged", error);
          handleError(error, setGenericErrorMessage, navigate);
        });
    }
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={questionMain3}>
            income
            <InformationCircleIcon
              className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
              onClick={() => setOpenWhy(true)}
            />
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-10 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <label
                htmlFor="numberOfDependents"
                className="block font-bold leading-6 text-gray-900"
              >
                including yourself, how many people in your household depend on your financial
                support?
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <SelectLocal
                  id="numberOfDependents"
                  options={dependentOptions}
                  currentValue={numberOfDependents}
                  setCurrentValue={(obj) => {
                    setNumberOfDependents(obj);
                    if (obj) {
                      let val = obj.value;
                      if (val === onlyMe) {
                        val = 1;
                      }
                      if (val === moreThan12) {
                        setShowInput(true);
                      } else {
                        setShowInput(false);
                        setOptions(personToRange[parseInt(val)]);
                      }
                      setIncomeRange("");
                      setIncomeRangeError(false);
                      setNumberOfDependentsError(false);
                    }
                  }}
                  // todo handle more than 12 household
                />
              </div>
              {numberOfDependentsError && (
                <p className={errorUnderInput} id="numberOfDependents-error">
                  Dependents selection is required.
                </p>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="incomeRange" className="block font-bold leading-6 text-gray-900">
                what is your annual (yearly) income?
              </label>
              {showInput ? (
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                    id="incomeRange"
                    value={incomeRange || ""}
                    onChange={(e) => {
                      setIncomeRange(e.target.value);
                      setIncomeRangeError(false);
                      setNumberOfDependentsError(false);
                    }}
                  />
                </div>
              ) : (
                <div className="relative mt-2 rounded-md shadow-sm">
                  <SelectLocal
                    id="incomeRange"
                    options={options}
                    currentValue={incomeRange}
                    setCurrentValue={(v) => {
                      setIncomeRange(v);
                      setIncomeRangeError(false);
                      setNumberOfDependentsError(false);
                    }}
                  />
                </div>
              )}
              {incomeRangeError && (
                <p className={errorUnderInput} id="incomeRange-error">
                  Income range is required.
                </p>
              )}
            </div>
            <WhyModal
              open={openWhy}
              setOpen={setOpenWhy}
              answer={`This data helps us determine whether you might qualify for legal aid under ${
                STATE_LIST[state.us_state]
              } law.`}
            />
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
