import {
  container,
  container2,
  header2,
  primaryButtonMax,
  buttonContainerCenter,
} from "../../../constants/classnames";
import { useLocation, useNavigate } from "react-router-dom";

export function Final() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // todo, need to get surveys here for info we might already have
  // also need to get previously save ps info if they got interrupted and came back

  const handleClick = () => {
    navigate("/menu", { state: state });
  };

  return (
    <div className={container}>
      <section id="heading">
        <div className={container2}>
          <h2 className={header2}>Thank you!</h2>
          <div className={buttonContainerCenter}>
            <button type="button" className={primaryButtonMax} onClick={handleClick}>
              back to menu
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
