export const genericMessage =
  "We're sorry, something went wrong. Please email us at tech-help@rasa-legal.com.";

export const checkTokenExpired = (error, navigate) => {
  if (
    error.response.data.detail.toLowerCase().includes("not auth") ||
    error.response.data.detail.toLowerCase().includes("unauth") ||
    error.response.data.detail.toLowerCase().includes("could not validate credentials")
  ) {
    navigate("/login");
  }
};

export const handleError = (error, setGenericErrorMessage, navigate) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.detail &&
    typeof error.response.data.detail === "string"
  ) {
    checkTokenExpired(error, navigate);
    setGenericErrorMessage(error.response.data.detail);
  } else {
    setGenericErrorMessage(genericMessage);
  }
};

export const handleEmailError = (error, setGenericErrorMessage, setEmailErrorMessage, navigate) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.detail &&
    typeof error.response.data.detail === "string"
  ) {
    checkTokenExpired(error, navigate);
    if (error.response.data.detail.includes("Email")) {
      setEmailErrorMessage(error.response.data.detail + ". Please use another email.");
    } else if (error && error.response && error.response.data && error.response.data.detail) {
      setGenericErrorMessage(error.response.data.detail);
    }
  } else {
    setGenericErrorMessage(genericMessage);
  }
};
