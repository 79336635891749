import { useLocation, useNavigate } from "react-router-dom";
import { primaryButton, secondaryButton } from "../../../../constants/classnames";
import { useEffect, useState } from "react";
import { isFreeUtm } from "../../../../constants/utmCodes";

export function DiscountQuestions() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // eslint-disable-next-line no-console
  console.log("state in discount questions", state);
  const [isFree, setIsFree] = useState(false);
  const [hasPeerReferrer, setHasPeerReferrer] = useState(false);
  const [amount, setAmount] = useState("15");

  useEffect(() => {
    if (state?.utm_code) {
      const utm_code = state.utm_code;
      if (isFreeUtm(utm_code)) {
        setIsFree(true);
      } else {
        setIsFree(false);
      }
    }
    if (state?.has_peer_referrer) {
      setHasPeerReferrer(true);
      setAmount("10");
    }
  }, [state]);

  const handleToDiscountQuestionsClick = () => {
    navigate("/register/employed", { state });
  };

  const handleToPaymentClick = () => {
    navigate("/register/records-ready", { state: { ...state, amount: amount } });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          {hasPeerReferrer && (
            <div>
              <div className="mb-6 rounded-md bg-pink-100 px-6 py-4 text-center">
                <div>This screening usually costs $15,</div>
                <div>but you are getting $5 off because you used your friend's referral link!</div>
              </div>
              <h3 className="font-sans text-xl font-bold">
                will you answer 12 short questions to see your results for free?
              </h3>
            </div>
          )}
          {isFree && (
            <h3 className="font-sans text-xl font-bold">
              The partnership that is covering the cost of this eligibility screening requires the
              following demographic questions to be answered.
            </h3>
          )}
          {!isFree && !hasPeerReferrer && (
            <h3 className="font-sans text-xl font-bold">
              will you answer 12 short questions for a $10 discount?
            </h3>
          )}
        </div>
      </section>
      <section id="bodyText">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <div className="mb-4">
            We are continually trying to build partnerships to lower the cost of record clearance
            services. Gathering data about how a record can impact a person's housing, employment,
            and education can help us make the case for these partnerships and clear more records.
          </div>
          <div className="mb-6">
            In addition, we want to make you aware of potential fair chance hiring opportunities,
            scholarships, and other benefits that could help a person with a record improve their
            life.
          </div>
          {!isFree && (
            <div className="mb-6">
              If you are willing to help us, we will provide a $10 discount on our eligibility tool.
            </div>
          )}
        </div>
      </section>
      <section id="button" className="mb-6">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col justify-center px-4">
          <div className="mb-6 overflow-hidden rounded-md border-2 border-gray-200 px-6 py-4">
            {/*<h3 className="mb-3 text-center font-sans text-xl font-bold">Check Eligibility: $15</h3>*/}
            {isFree ? (
              <button
                type="button"
                onClick={handleToDiscountQuestionsClick}
                className={primaryButton}
              >
                continue
              </button>
            ) : (
              <>
                <button
                  type="button"
                  onClick={handleToDiscountQuestionsClick}
                  className={primaryButton}
                >
                  {hasPeerReferrer ? "continue for free" : "get $10 discount"}
                </button>
                <div className="mt-2">
                  {/*<h3 className="mb-3 text-center font-sans text-xl font-bold">Check Eligibility: $15</h3>*/}
                  <button type="button" onClick={handleToPaymentClick} className={secondaryButton}>
                    go to ${amount} payment
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
