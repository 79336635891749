import RasaLogo from "@images/rasa-logo.svg";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { createMessage } from "../../../api/secureApi";
import { useState } from "react";
import { loading } from "../../../components/loading";
import { cannedMessageOptionsAz, cannedMessageOptionsUt } from "../register/surveyOptional/options";
import { Error } from "../../../components/error";
import { useForm } from "react-hook-form";
import { UT } from "../../../constants/usStates";
import { textareaClass } from "../../../constants/classnames";
import { SelectMessageUs } from "./MessageUsSelect";

export function MessageUsModal({
  open,
  setOpen,
  profileId,
  usState,
  setReceivedMessage,
  setReceivedAnyMessage,
}) {
  // const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const message =
      "canned_message: " + data.canned_message.value + ". typed message: " + data.message;
    const dataToSubmit = {
      body: message,
      type: "message",
    };
    setIsLoading(true);
    createMessage(profileId, dataToSubmit)
      .then((response) => {
        setError("");
        // eslint-disable-next-line no-console
        console.log("response", response);
        setReceivedMessage(true);
        setReceivedAnyMessage(true);
        setOpen(false);
        setIsLoading(false);
        // toast.success("Your message has been sent to Rasa.");
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error", error);
        setReceivedMessage(false);
        setError(
          "There was an error sending your message." +
            " Please email us at tech-help@rasa-legal.com",
        );
        setIsLoading(false);
      });
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[75vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="flex justify-center">
              <RasaLogo className="h-9" />
            </div>
            <AlertDialog.Description className="mb-8 px-6 text-base">
              <section id="form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="container mx-auto mb-16 mt-6 flex max-w-3xl flex-col px-4">
                    <div className="pb-4">
                      <label htmlFor="canned_message" className="block leading-6 text-gray-900">
                        We get many requests for contact. Please select your main reason for wanting
                        to chat with us.
                      </label>
                      <div className="relative mb-6 mt-2 rounded-md shadow-sm">
                        <SelectMessageUs
                          id="canned_message"
                          name="canned_message"
                          options={usState === UT ? cannedMessageOptionsUt : cannedMessageOptionsAz}
                          register={register}
                          {...register("canned_message", { required: true })}
                        />
                        <Error errors={errors} name="canned_message" />
                      </div>
                      <label htmlFor="message" className="block leading-6 text-gray-900">
                        Please provide additional details about your situation that would help our
                        team prepare to help you understand your options.
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <textarea
                          placeholder="example: my 2003 theft case was closed in 2005 and I think I am eligible"
                          type="text"
                          name="message"
                          className={textareaClass}
                          rows="10"
                          {...register("message", { required: true })}
                        ></textarea>
                      </div>
                      <Error errors={errors} name="message" />
                    </div>
                    <div className="flex flex-col justify-center">
                      {error && (
                        <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                          {error}
                        </div>
                      )}
                      <button
                        type="submit"
                        className="my-4 w-full rounded-full bg-rasapink p-4 font-semibold text-white hover:shadow-xl disabled:opacity-50"
                      >
                        {isLoading ? loading : "send message"}
                      </button>
                    </div>
                  </div>
                </form>
              </section>
            </AlertDialog.Description>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
