import { vocUtm } from "../../../../constants/utmCodes";

export const getQuestionNumber = (question, utm) => {
  if (utm === vocUtm) {
    // if utm is voc, then we don't show the disability question
    switch (question) {
      case "health_insurance":
        return "7.";
      case "denied_housing":
        return "8.";
      case "denied_employment":
        return "9.";
      case "clearing_reason":
        return "10.";
      case "interested_info":
        return "11.";
      default:
        return "";
    }
  } else {
    switch (question) {
      case "health_insurance":
        return "8.";
      case "denied_housing":
        return "9.";
      case "denied_employment":
        return "10.";
      case "clearing_reason":
        return "11.";
      case "interested_info":
        return "12.";
      default:
        return "";
    }
  }
};
