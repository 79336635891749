import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

export function InfoModal({ open, setOpen, answer }) {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] rounded-lg bg-white p-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="mb-4 flex justify-center">
            <InformationCircleIcon className="h-20 text-pink-100" />
          </div>
          <AlertDialog.Description className="mb-8">{answer}</AlertDialog.Description>
          <button
            type="button"
            className="w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
            onClick={() => setOpen(false)}
          >
            ok
          </button>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
