import { getPriceSentence } from "./PriceBox";
import { UT } from "../../../constants/usStates";

export const CleanSlateBox = ({ isFeeWaiverEligible }) => {
  return (
    <div className="mb-1 rounded border border-rasalightpurple bg-rasa-purple1-50 p-1 text-center leading-5">
      <div>
        It looks like you may be working with our community partner, <b>Clean Slate Utah.</b>
      </div>
      <div className="mt-3">
        While{" "}
        <span className="text-rasapink underline">
          <a target="_blank" rel="noreferrer" href="https://www.cleanslateutah.org/">
            Clean Slate Utah
          </a>
        </span>{" "}
        does NOT provide legal services, they can answer questions if you want to do your own legal
        paperwork.
      </div>
      <div className="mt-3">
        If you would like to hire Rasa as your lawyer, our fees start at{" "}
        {getPriceSentence(UT, isFeeWaiverEligible)}
      </div>
      {isFeeWaiverEligible && (
        <div className="text-xs text-gray-700">*based on government fee waiver eligibility</div>
      )}
    </div>
  );
};
