import {
  container2,
  questionMain,
  question,
  textareaClass,
  inputWrapper,
  input,
  animate,
  pinkSection,
  errorClass,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { HOUSING } from "./overview";
import { handleError } from "../../../utils/handleError";
import { useEffect, useState } from "react";
import { handleSave } from "./handleSave";
import { RadioPS } from "./radioPS";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";

// selection
const HOMEOWNER = "homeowner";
const RENTING = "renting";

// additional
const DETAILS = "details";
const HOME_LOCATION = "home_location";

export function Housing() {
  const navigate = useNavigate();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [HOUSING]: "",
    },
  });
  const current = watch(HOUSING);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(HOUSING)
      .then((response) => {
        response.forEach((row) => {
          setValue(HOUSING, row.selection);
          if (row.additional_json) {
            if (row.additional_json[DETAILS]) {
              setValue(DETAILS, row.additional_json[DETAILS]);
            }
            if (row.additional_json[HOME_LOCATION]) {
              setValue(HOME_LOCATION, row.additional_json[HOME_LOCATION]);
            }
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  const handlePreSave = (path) => {
    const values = getValues();
    if (values[HOUSING]) {
      // eslint-disable-next-line no-console
      console.log("values", values);
      setGenericErrorMessage("");
      const additional = {};
      if (values[DETAILS]) {
        additional[DETAILS] = values[DETAILS];
      }
      if (values[HOME_LOCATION]) {
        additional[HOME_LOCATION] = values[HOME_LOCATION];
      }
      const newDetails = [
        {
          selection: values[HOUSING],
          additional_json: additional,
        },
      ];
      handleSave(HOUSING, newDetails, navigate, path);
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/grandchildren";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/life-improvement";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="heading">
        <div className={container2}>
          <div className={questionMain}>What is your housing situation?</div>
          <div className="pb-4">
            <div className={current === HOMEOWNER ? pinkSection : ""}>
              <RadioPS
                name={HOUSING}
                answer={HOMEOWNER}
                label={HOMEOWNER}
                register={register}
                {...register(HOUSING)}
                current={current}
              />
              {current === HOMEOWNER && (
                <div className={animate}>
                  <h2 className={question}>Where is your home located?</h2>
                  <div className={inputWrapper}>
                    <input
                      placeholder="City, State"
                      type="text"
                      name={HOME_LOCATION}
                      id={HOME_LOCATION}
                      className={input}
                      {...register(HOME_LOCATION)}
                    />
                  </div>
                </div>
              )}
            </div>
            <RadioPS
              name={HOUSING}
              answer={RENTING}
              label={RENTING}
              register={register}
              {...register(HOUSING)}
              current={current}
            />
            <RadioPS
              name={HOUSING}
              answer="housing is unstable (couch surfing, living in car, homeless, etc.)"
              label="housing is unstable (couch surfing, living in car, homeless, etc.)"
              register={register}
              {...register(HOUSING)}
              current={current}
            />
            <RadioPS
              name={HOUSING}
              answer="other"
              label="other"
              register={register}
              {...register(HOUSING)}
              current={current}
            />
          </div>
          <div className={question}>Please give details here, if appropriate</div>
          <textarea
            placeholder="Nobody will rent to me because of my record"
            type="text"
            name={DETAILS}
            id={DETAILS}
            className={textareaClass}
            rows="4"
            {...register(DETAILS)}
          ></textarea>
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext handleBackClick={handleBackClick} handleNextClick={handleNextClick} />
        </div>
      </section>
    </>
  );
}
