import { useEffect, useState } from "react";
import { toTitleCase } from "../../../utils/toTitleCase";
import { cleanDispositionString } from "./utils";
import { CardUT } from "./CardUT";
import {
  AUTO_EXPUNGEMENT,
  ELIGIBLE,
  EXPUNGEMENT,
  FELONY,
  INELIGIBLE,
  INFRACTION,
  MISDEMEANOR,
  PARKING,
  TRAFFIC,
  UNKNOWN,
} from "./results";
import { recordLabel } from "../../../constants/classnames";

const dateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const compare = (a, b) => {
  if (a.props.data.dispositionDate > b.props.data.dispositionDate) {
    return -1;
  }
  if (a.props.data.dispositionDate < b.props.data.dispositionDate) {
    return 1;
  }
  return 0;
};

const cleanReasons = (reasonsArray) => {
  let reasonsStr = reasonsArray.join(", ");
  reasonsStr = reasonsStr.replaceAll("Too", "too").replaceAll("Has ", "");
  return reasonsStr;
};

export const CardSectionUT = ({
  data,
  ineligibleReasons,
  openCases,
  openMessageUs,
  onProbationOrParole,
  isFeeWaiverEligible,
  receivedAnyMessage,
  setIneligibleReasons,
  setOneIsEligible,
  setOpenMessageUs,
  hasFreshworks,
  setCriminalCount,
  setTrafficCount,
}) => {
  const [misdemeanorCards, setMisdemeanorCards] = useState([]);
  const [felonyCards, setFelonyCards] = useState([]);
  const [infractionCards, setInfractionCards] = useState([]);
  const [trafficCards, setTrafficCards] = useState([]);
  const [uncategorizedCards, setUncategorizedCards] = useState([]);

  useEffect(() => {
    const missCards = [];
    const felCards = [];
    const trafCards = [];
    const infracCards = [];
    const uncatCards = [];
    // eslint-disable-next-line no-console
    console.log("data before data.map", data);
    // in the record obj, we get each remedy that is eligible,
    // then we get the lead charge within that record
    data.map((record) => {
      let expungementEligibleDate = "";
      let expungementEligibleNow = false;
      let expungementEligibleLater = false;
      let autoExpungementEligibleDate = "";
      let autoExpungementEligibleNow = false;
      let autoExpungementEligibleLater = false;
      let needMoreInfo = false;

      let leadChargeNumber = record.lead_charge;
      let leadCharge = record.charges.find((charge) => charge.count_number === leadChargeNumber);
      let reasons = "";
      leadCharge.remedies.forEach((remedy) => {
        const today = new Date();
        if (remedy.type.toLowerCase() === EXPUNGEMENT) {
          const eligibleBy = remedy.eligible_by ? new Date(remedy.eligible_by) : null;
          if (remedy.eligibility.toLowerCase() === INELIGIBLE) {
            expungementEligibleNow = false;
            reasons = cleanReasons(remedy.reasons);
            let globalReasonsString = ineligibleReasons.slice();
            remedy.reasons.forEach((reason) => {
              let reasonStr = reason
                .replace("Too", "too")
                .replace("Has open case", "open case")
                .replace("Under DOC supervision", "under DOC supervision");
              if (
                reasonStr.includes("too many") ||
                reasonStr.includes("open case") ||
                reasonStr.includes("under DOC supervision")
              ) {
                if (!globalReasonsString.includes(reasonStr)) {
                  if (globalReasonsString === "") {
                    globalReasonsString += reasonStr;
                  } else {
                    globalReasonsString += ", " + reasonStr;
                  }
                }
              }
            });
            setIneligibleReasons(globalReasonsString);
            if (globalReasonsString === "" && eligibleBy > today) {
              expungementEligibleLater = true;
              expungementEligibleDate = eligibleBy.toLocaleDateString("en-US", dateOptions);
            }
          } else if (remedy.eligibility.toLowerCase() === ELIGIBLE) {
            expungementEligibleNow = true;
            if (!openCases || !onProbationOrParole) {
              setOneIsEligible(true);
            }
          } else if (remedy.eligibility.toLowerCase() === UNKNOWN) {
            needMoreInfo = true;
          }
        } else if (remedy.type.toLowerCase() === AUTO_EXPUNGEMENT) {
          const eligibleBy = remedy.eligible_by ? new Date(remedy.eligible_by) : null;
          if (remedy.eligibility.toLowerCase() === INELIGIBLE) {
            autoExpungementEligibleNow = false;
            reasons = cleanReasons(remedy.reasons);
          } else if (remedy.eligibility.toLowerCase() === ELIGIBLE) {
            autoExpungementEligibleNow = true;
          } else if (eligibleBy > today) {
            autoExpungementEligibleLater = true;
            autoExpungementEligibleDate = eligibleBy.toLocaleDateString("en-US", dateOptions);
          }
        }
      });

      const cardData = {
        caseNumber: record.case_number,
        court: record.court,
        violation: leadCharge.description
          ? toTitleCase(leadCharge.description.toLowerCase())
          : "--",
        violationCode: record.code,
        disposition: leadCharge.disposition ? cleanDispositionString(leadCharge.disposition) : "--",
        dispositionDate: leadCharge.disposition_date
          ? toTitleCase(leadCharge.disposition_date.toLowerCase())
          : "--",
        severity: leadCharge.severity ? leadCharge.severity.toUpperCase() : "--",
        charges: record.charges,
        isFeeWaiverEligible: isFeeWaiverEligible,
        expungementEligibleDate: expungementEligibleDate,
        expungementEligibleNow: expungementEligibleNow,
        expungementEligibleLater: expungementEligibleLater,
        autoExpungementEligibleDate: autoExpungementEligibleDate,
        autoExpungementEligibleNow: autoExpungementEligibleNow,
        autoExpungementEligibleLater: autoExpungementEligibleLater,
        reasons: reasons,
        needMoreInfo: needMoreInfo,
      };

      const currentCard = (
        <CardUT
          key={cardData.caseNumber}
          data={cardData}
          setOpenMessageUs={setOpenMessageUs}
          openCases={openCases}
          onProbationOrParole={onProbationOrParole}
          receivedAnyMessage={receivedAnyMessage}
          hasFreshworks={hasFreshworks}
        />
      );

      if (
        record.case_type?.toLowerCase().includes(TRAFFIC) ||
        record.case_type?.toLowerCase().includes(PARKING)
      ) {
        trafCards.push(currentCard);
      } else if (leadCharge.severity_type.toLowerCase().includes(INFRACTION)) {
        infracCards.push(currentCard);
      } else if (leadCharge.severity_type.toLowerCase().includes(MISDEMEANOR)) {
        missCards.push(currentCard);
      } else if (leadCharge.severity_type.toLowerCase().includes(FELONY)) {
        felCards.push(currentCard);
      } else {
        uncatCards.push(currentCard);
      }
    });

    const felSorted = felCards.sort(compare);
    const misSorted = missCards.sort(compare);
    const infracSorted = infracCards.sort(compare);
    const trafSorted = trafCards.sort(compare);
    const uncatSorted = uncatCards.sort(compare);

    setFelonyCards(felSorted);
    setMisdemeanorCards(misSorted);
    setInfractionCards(infracSorted);
    setTrafficCards(trafSorted);
    setUncategorizedCards(uncatSorted);
    setCriminalCount(felCards.length + misSorted.length + infracSorted.length);
    setTrafficCount(trafSorted.length);
  }, [
    openMessageUs,
    data,
    openCases,
    onProbationOrParole,
    isFeeWaiverEligible,
    receivedAnyMessage,
    ineligibleReasons,
    setIneligibleReasons,
    setOneIsEligible,
    setOpenMessageUs,
    hasFreshworks,
    setCriminalCount,
    setTrafficCount,
  ]);

  return (
    <>
      <section className="bg-pink-100 pb-12 pt-1">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className={recordLabel}>felony records</h3>
          <div>
            We found {felonyCards.length === 0 ? "no" : felonyCards.length} felony{" "}
            {felonyCards.length === 1 ? "record" : "records"}
            {felonyCards.length === 0 ? "." : ":"}
          </div>
          {felonyCards.map((card) => card)}
        </div>
      </section>
      <section className="bg-gray-200 pb-12 pt-1">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className={recordLabel}>misdemeanor records</h3>
          <div>
            We found {misdemeanorCards.length === 0 ? "no" : misdemeanorCards.length} misdemeanor{" "}
            {misdemeanorCards.length === 1 ? "record" : "records"}
            {misdemeanorCards.length === 0 ? "." : ":"}
          </div>
          {misdemeanorCards.map((card) => card)}
        </div>
      </section>
      <section className="bg-gray-50 pb-12 pt-1">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className={recordLabel}>infraction records</h3>
          <div>
            We found {infractionCards.length === 0 ? "no" : infractionCards.length} infraction{" "}
            {infractionCards.length === 1 ? "record" : "records"}
            {infractionCards.length === 0 ? "." : ":"}
          </div>
          {infractionCards.map((card) => card)}
        </div>
      </section>
      <section className="bg-blue-50 pb-12 pt-1">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className={recordLabel}>traffic records</h3>
          <div>
            We found {trafficCards.length === 0 ? "no" : trafficCards.length} traffic{" "}
            {trafficCards.length === 1 ? "record" : "records"}
            {trafficCards.length === 0 ? "." : ":"}
          </div>
          {trafficCards.map((card) => card)}
        </div>
      </section>
      <section className="bg-gray-100 pb-12 pt-1">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className={recordLabel}>other records</h3>
          <div>
            We found {uncategorizedCards.length === 0 ? "no" : uncategorizedCards.length} other{" "}
            {uncategorizedCards.length === 1 ? "record" : "records"}
            {uncategorizedCards.length === 0 ? "." : ":"}
          </div>
          {uncategorizedCards.map((card) => card)}
        </div>
      </section>
    </>
  );
};
