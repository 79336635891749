export const REFERRAL_SOURCE = [
  { value: "", text: "-- Select an option --" },
  { value: "communityPartnerSpecify", text: "Community Partner (please specify below)" },
  { value: "employerSpecify", text: "Employer (please specify below)" },
  { value: "facebook", text: "Facebook" },
  { value: "flyer", text: "Flyer" },
  { value: "googleSearch", text: "Google Search" },
  { value: "instagram", text: "Instagram" },
  { value: "newsRadioSpecify", text: "News - RadioPS (please specify below)" },
  { value: "newsTvSpecify", text: "News - TV (please specify below)" },
  { value: "newsWebsiteSpecify", text: "News - Website (please specify below)" },
  { value: "rasaEmail", text: "Rasa Email" },
  { value: "tiktok", text: "TikTok" },
  { value: "wordOfMouthSpecify", text: "Word of Mouth / Referral (please specify below)" },
  { value: "otherSpecify", text: "Other (please specify below)" },
];
