import {
  draftingFiling,
  prosecutorReview,
  motionComplete,
  waitingForCourt,
  paymentNeededStatus,
  paymentNeededDescription,
  clientWithdrew,
  getClientDisengaged,
  caseWithdrawn,
  caseWithdrawnDescription,
} from "./DescriptionsExpungement";
import { ClioLink } from "../Clio/ClioPage";
import { format, parseISO } from "date-fns";
import { formatShortString } from "./utils";

export const getOtherMotionDraftingDescription = (isCurrent) => {
  if (isCurrent) {
    return "We are currently drafting and filing your legal documents.";
  }
  return "During this phase, Rasa is drafting and filing your legal documents.";
};

export const getOtherMotionProsecutorReviewDescription = (isCurrent, filedDate) => {
  let formattedDate;
  if (filedDate) {
    formattedDate = format(parseISO(filedDate), formatShortString);
  }
  if (isCurrent) {
    return (
      <>
        <div>
          Congratulations! Rasa filed your court paperwork{filedDate && ` on ${formattedDate}`}. We
          are now waiting for the prosecutor to review it.
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        During this phase, we wait for the prosecutor to review the court documents drafted and
        filed by Rasa.
      </div>
    </>
  );
};

export const getOtherMotionWaitingForCourtDescription = (isCurrent) => {
  if (isCurrent) {
    return (
      "Good news! Your documents have been filed and we have not received any objections to" +
      " your motion. We are now waiting for the court to make a final decision."
    );
  }
  return (
    "At this point, your documents have been filed and we have not received any objections to" +
    " your motion. We wait for the court to make a final decision."
  );
};

export const getOtherMotionCompleteDescription = (isCurrent) => {
  if (isCurrent) {
    return (
      <div>
        Everything is complete! Watch your <ClioLink /> portal for copies of your motion and court
        order.
      </div>
    );
  }
  return (
    "When everything is complete, Rasa will make all your documents" +
    " available for you to download and keep for your records."
  );
};

export const getOtherMotionDescription = (status, filedDate) => {
  switch (status) {
    case draftingFiling:
      return getOtherMotionDraftingDescription(true);
    case prosecutorReview:
      return getOtherMotionProsecutorReviewDescription(true, filedDate);
    case waitingForCourt:
      return getOtherMotionWaitingForCourtDescription(true);
    case motionComplete:
      return getOtherMotionCompleteDescription(true);
    case paymentNeededStatus:
      return paymentNeededDescription;
    case clientWithdrew:
      return getClientDisengaged();
    case caseWithdrawn:
      return caseWithdrawnDescription;
    default:
      return "The status of this case is not known. Please contact Rasa for more information.";
  }
};
