import {
  container2,
  header2,
  buttonContainerCenter,
  primaryButtonMax,
} from "../../../constants/classnames";
import { useLocation, useNavigate } from "react-router-dom";

const LI = "my-2";

export function PersonalInfoIntro() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // todo, need to get surveys here for info we might already have
  // also need to get previously save ps info if they got interrupted and came back

  const handleClick = () => {
    navigate("/statement-overview", { state: state });
  };

  return (
    <>
      <section id="heading">
        <div className={container2}>
          <h2 className={header2}>personal statement questions</h2>
          <div className="mx-3">
            <div className={LI}>
              In order to get started on your legal work, we need to collect some information about
              you.
            </div>
            <ul className="mx-3 list-disc">
              <li className={LI}>
                Please take about 10 minutes to answer the following questions.
              </li>
              <li className={LI}>Your progress will be saved as you go.</li>
            </ul>
          </div>
          <div className="rounded-md bg-pink-100 p-4 text-center">
            <b>
              Success of record clearance is greater when we include this personal information in
              legal documents.
            </b>
          </div>
          <div className={buttonContainerCenter}>
            <button type="button" className={primaryButtonMax} onClick={handleClick}>
              get started
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
