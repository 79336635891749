import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { MANDATORY_SURVEY } from "../../../../constants/surveyTypes";
import { updatePerson } from "../../../../api/secureApi";
import { handleError } from "../../../../utils/handleError";
import { useState } from "react";
import {
  errorUnderInput,
  input,
  inputLabel,
  inputWrapper,
  questionMain3,
} from "../../../../constants/classnames";

export function VocCaseManager() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    const stateToSubmit = {
      profile_id: state.profile_id,
      surveys: [
        {
          type: MANDATORY_SURVEY,
          answers: {
            race: currentMandatorySurvey.answers.race,
            race_other: currentMandatorySurvey.answers.race_other || "", // optional field
            ethnicity: currentMandatorySurvey.answers.ethnicity,
            open_cases: currentMandatorySurvey.answers.open_cases,
            on_probation_or_parole: currentMandatorySurvey.answers.on_probation_or_parole,
            other_convictions: currentMandatorySurvey.answers.other_convictions,
            other_convictions_list: currentMandatorySurvey.answers.other_convictions_list || [], // optional field
            expunged_before: currentMandatorySurvey.answers.expunged_before,
            expunged_before_story: currentMandatorySurvey.answers.expunged_before_story || "", // optional field
            number_of_dependents: currentMandatorySurvey.number_of_dependents,
            income_range: currentMandatorySurvey.income_range,
            income_range_fpl: currentMandatorySurvey.answers.income_range_fpl,
            government_benefit: currentMandatorySurvey.government_benefit,
            is_fee_waiver_eligible: currentMandatorySurvey.is_fee_waiver_eligible,
            voc_case_manager: data.voc_case_manager,
          },
        },
      ],
    };

    updatePerson(stateToSubmit)
      .then((response) => {
        navigate("/register/court-data", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in ethn", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <div id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={questionMain3}>
            It looks like you are working with Vocational Rehabilitation
          </h2>
        </div>
      </div>
      <div id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto mb-10 mt-6 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <label htmlFor="voc_case_manager" className={inputLabel}>
                Please tell us the full name of your Voc Rehab case manager.
              </label>
              <div className={inputWrapper}>
                <input
                  type="text"
                  name="voc_case_manager"
                  id="voc_case_manager"
                  className={input}
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="voc_case_manager-error"
                  {...register("voc_case_manager", { required: true })}
                />
              </div>
              {errors.voc_case_manager && (
                <p className={errorUnderInput} id="voc_case_manager-error">
                  Answer is required.
                </p>
              )}
            </div>
            <div className="pb-4">
              If you do not know the name of your case manager, please contact{" "}
              <span className="text-rasapink underline">
                <a target="_blank" rel="noreferrer" href="https://jobs.utah.gov/usor/vr/index.html">
                  Vocational Rehabilitation
                </a>
              </span>{" "}
              to find out.
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 text-sm font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
