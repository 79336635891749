import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Error } from "../../../../components/error";
import { MANDATORY_SURVEY, OPTIONAL_SURVEY } from "../../../../constants/surveyTypes";
import { updatePerson } from "../../../../api/secureApi";
import { adjustState } from "./utils";
import { container3 } from "../../../../constants/classnames";

export function PayRate() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const newState = { ...state, ...data };
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    let currentOptionalSurvey = state.surveys.find((survey) => survey.type === OPTIONAL_SURVEY);
    if (currentOptionalSurvey) {
      currentOptionalSurvey.answers.hourly_rate = data.hourly_rate;
    } else {
      currentOptionalSurvey = {
        type: OPTIONAL_SURVEY,
        answers: {
          hourly_rate: data.hourly_rate,
        },
      };
    }
    const stateToSubmit = {
      profile_id: newState.profile_id,
      surveys: [currentOptionalSurvey, currentMandatorySurvey],
    };
    adjustState(state, stateToSubmit);

    // eslint-disable-next-line no-console
    console.log("stateToSubmit in pay rate", stateToSubmit);

    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in pay rate", response);
        navigate("/register/industry-current", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in pay rate", error);
        //   todo something here
        // if (typeof error.response.data.detail === "string") {
        //   if (error && error.response && error.response.data && error.response.data.detail) {
        //     setGenericErrorMessage(error.response.data.detail);
        //   }
        // } else {
        //   setGenericErrorMessage("An error has occurred.");
        // }
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">2. How much do you make per hour?</h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={container3}>
            <div className="relative mb-4 mt-2 rounded-md shadow-sm">
              <select
                id="hourly_rate"
                name="hourly_rate"
                className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                defaultValue=""
                {...register("hourly_rate", { required: true })}
              >
                <option value="" disabled>
                  Per hour
                </option>
                <option value="underFifteen">$7 - $15 / hour</option>
                <option value="15to20">$15 - $20 / hour</option>
                <option value="20to25">$20 - $25 / hour</option>
                <option value="25to30">$25 - $30 / hour</option>
                <option value="25to30">$30 - $50 / hour</option>
                <option value="moreThan30">more than $50 / hour</option>
              </select>
              <Error errors={errors} name="hourly_rate" />
            </div>
            <div className="flex flex-col justify-center">
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
