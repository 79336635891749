import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { useFieldArray, useForm } from "react-hook-form";
import { STATE_LIST } from "@constants/usStates";
import { useState } from "react";

export function Step2Form({ next, usState }) {
  const [showOtherInput, setShowOtherInput] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      convictions: [
        {
          location: "",
          conviction_type: "",
          conviction_type_other: "",
          severity: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({ control, name: "convictions" });

  const onSubmit = (data) => {
    next(data);
  };

  return (
    <div className="flex h-[95vh] flex-col py-6">
      <AlertDialog.Title className="mb-4 px-6 font-serif text-xl lowercase">
        add non-{usState} convictions
      </AlertDialog.Title>
      {/*<AlertDialog.Description className="mb-8 px-6">*/}
      {/*  You can edit this later if you want to add more information.*/}
      {/*</AlertDialog.Description>*/}
      <div className="grow overflow-auto px-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex max-w-3xl flex-col pb-8">
            {/* display alias fields */}
            <ul>
              {fields.map((item, index) => {
                return (
                  <li key={item.id}>
                    {/* card */}
                    <div className="mb-6 overflow-hidden rounded-md border-2 border-gray-200 p-3">
                      <h2 className="mb-3 font-serif text-xl">{`conviction ${index + 1}`}</h2>
                      {/*<p className="mb-4">In which state did your conviction occur?</p>*/}
                      {/* Conviction State */}
                      <div className="pb-4">
                        <label
                          htmlFor={`state-convictions-${item.id}`}
                          className="block font-bold leading-6 text-gray-900"
                        >
                          location *
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <select
                            id="location"
                            name="location"
                            className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                            placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                            defaultValue=""
                            {...register(`convictions.${index}.location`, { required: true })}
                          >
                            <option value="" disabled />
                            <option value="federal">Federal</option>
                            {Object.entries(STATE_LIST).map(([k, v]) => (
                              <option key={k} value={k}>
                                {v}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.convictions && errors.convictions[index]?.location && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id={`convictions.${index}.location-error`}
                          >
                            Location is required.
                          </p>
                        )}
                      </div>
                      <div className="pb-4">
                        <label
                          htmlFor="offense"
                          className="block font-bold leading-6 text-gray-900"
                        >
                          type of offense *
                        </label>
                        <div className="relative my-2 rounded-md shadow-sm">
                          <select
                            id="conviction_type"
                            name="conviction_type"
                            className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                            defaultValue=""
                            onChangeCapture={(e) => {
                              const newList = [...showOtherInput];
                              if (e.target.value === "other") {
                                newList.push(index);
                                setShowOtherInput(newList);
                              } else {
                                const indexToRemove = newList.indexOf(index);
                                setShowOtherInput(newList.splice(indexToRemove, 1));
                              }
                            }}
                            {...register(`convictions.${index}.conviction_type`, {
                              required: true,
                            })}
                          >
                            <option value="" disabled />
                            <option value="drug">Drug related</option>
                            <option value="dui">DUI</option>
                            <option value="property">Theft or property related</option>
                            <option value="other">Other (please specify below)</option>
                          </select>
                          {errors.convictions && errors.convictions[index]?.conviction_type && (
                            <p className="mt-2 text-sm text-red-600" id="offense-error">
                              Type of the offense is required.
                            </p>
                          )}
                        </div>
                        {showOtherInput.includes(index) && (
                          <div className="py-4">
                            <label
                              htmlFor="conviction_type_other"
                              className="block font-bold leading-6 text-gray-900"
                            >
                              other type *
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <input
                                type="text"
                                name="conviction_type_other"
                                id="conviction_type_other"
                                className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                                defaultValue=""
                                aria-invalid="false"
                                aria-describedby="conviction_type_other-error"
                                {...register(`convictions.${index}.conviction_type_other`)}
                              />
                            </div>
                            {errors.conviction_type_other && (
                              <p
                                className="mt-2 text-sm text-red-600"
                                id="conviction_type_other-error"
                              >
                                Answer is required.
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="pb-4">
                        <label
                          htmlFor="offense"
                          className="block font-bold leading-6 text-gray-900"
                        >
                          severity of offense *
                        </label>
                        <div className="relative my-2 rounded-md shadow-sm">
                          <select
                            id="severity"
                            name="severity"
                            className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                            defaultValue=""
                            {...register(`convictions.${index}.severity`, { required: true })}
                          >
                            <option value="" disabled />
                            <option value="misdemeanor">Misdemeanor</option>
                            <option value="felony">Felony</option>
                            <option value="doNotKnow">I'm not sure</option>
                          </select>
                          {errors.convictions && errors.convictions[index]?.severity && (
                            <p className="mt-2 text-sm text-red-600" id="offense-error">
                              Severity of the offense is required.
                            </p>
                          )}
                        </div>
                      </div>
                      {/* remove conviction button */}
                      {index !== 0 && (
                        <div className="flex justify-end">
                          <button
                            type="button"
                            className="mr-2 inline-flex items-center justify-center text-rasapink transition-colors duration-150 hover:shadow-xl"
                            onClick={() => remove(index)}
                          >
                            <MinusCircleIcon className="h-4 w-4 text-rasapink" />
                            remove conviction
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="mt-4">
              <button
                type="button"
                className="mr-2 inline-flex items-center justify-center text-rasapink transition-colors duration-150 hover:shadow-xl"
                onClick={() =>
                  append({
                    location: "",
                    conviction_type: "",
                    conviction_type_other: "",
                    severity: "",
                  })
                }
              >
                <PlusCircleIcon className="h-4 w-4 text-rasapink" />
                add conviction
              </button>
            </div>
          </div>
          <div className="mb-16 flex flex-col justify-center">
            <button
              type="submit"
              className="w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
            >
              next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
