export const VocBox = () => {
  return (
    <div className="mt-1 rounded border border-rasablue bg-rasa-blue-50 p-1 text-center leading-6">
      <div>
        You may qualify to get help from our community partner,{" "}
        <span className="text-rasapink underline">
          <a target="_blank" rel="noreferrer" href="https://jobs.utah.gov/usor/vr/index.html">
            Vocational Rehabilitation
          </a>
        </span>
      </div>
      <div className="mt-3">
        Please apply for their services and have your Voc Rehab case manager contact Rasa.
      </div>
      <div className="mt-3 font-bold">
        Vocational Rehabilitation may pay legal and government fees for Rasa to handle your record
        clearance!
      </div>
    </div>
  );
};
