import { AlertModal } from "@components";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { useForm } from "react-hook-form";
import { errorUnderInput } from "../../../../../constants/classnames";

export function ExpungedModal({ open, setOpen, done }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });
  const onSubmit = (data) => {
    setOpen(false);
    done(data);
  };
  return (
    <AlertModal open={open} className="p-0">
      <div className="flex h-[95vh] flex-col py-6">
        <AlertDialog.Title className="mb-4 px-6 font-serif text-xl">
          if you've cleared your record before, it could impact whether you are eligible now.
        </AlertDialog.Title>
        <AlertDialog.Description className="mb-8 px-6">
          Please provide us with more information about the records you have already cleared:
        </AlertDialog.Description>
        <div className="grow overflow-auto px-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex max-w-3xl flex-col pb-8">
              <textarea
                placeholder="Tell us your story"
                name="previousExpungement"
                rows="8"
                {...register("expunged_before_story", { required: true })}
              ></textarea>
              {errors.expunged_before_story && (
                <p className={errorUnderInput} id="parole-error">
                  Please tell us about your previous cleared records.
                </p>
              )}
            </div>
            <div className="flex flex-col justify-center">
              <button
                type="submit"
                className="w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </form>
        </div>
      </div>
    </AlertModal>
  );
}
