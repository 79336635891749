import { toTitleCase } from "../../../utils/toTitleCase";

export const cleanDispositionString = (disposition) => {
  disposition = disposition.toLowerCase();
  if (disposition.startsWith("{")) {
    disposition = disposition.substring(1);
  }
  if (disposition.endsWith("}")) {
    disposition = disposition.substring(0, disposition.length - 1);
  }

  if (disposition === "dismissed (w/o prej)") {
    disposition = "Dismissed without prejudice";
  } else if (disposition === "dismissed w/ prejudi") {
    disposition = "Dismissed with prejudice";
  }
  return toTitleCase(disposition);
};

export const getSeverityString = (code) => {
  code = code.trim();
  const mapping = {
    F1: "Felony 1st Degree",
    F2: "Felony 2nd Degree",
    F3: "Felony 3rd Degree",
    MA: "Misdemeanor Class A",
    MB: "Misdemeanor Class B",
    MC: "Misdemeanor Class C",
    IN: "Infraction",
    NA: "--",
  };
  if (mapping[code]) {
    return mapping[code];
  }
  return "--";
};
