import RasaLogo from "@images/rasa-logo.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getEligibility, getPerson } from "../../../api/secureApi";

export function TestPage() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    // control,
  } = useForm({ mode: "onBlur" });

  // const { fields, append, remove } = useFieldArray({ control, name: "aliases" });

  const onSubmit = (data) => {
    // eslint-disable-next-line no-console
    console.log("before nav", data);
    getEligibility()
      .then((eliResponse) => {
        // eslint-disable-next-line no-console
        console.log("eliResponse in get Eli", eliResponse);
        getPerson()
          .then((personResponse) => {
            navigate("/results", { state: { data: eliResponse, ...personResponse } });
          })
          .catch((personError) => {
            // eslint-disable-next-line no-console
            console.log("personError", personError);
          });
        navigate("/results", { state: { data: eliResponse } });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in get Eli", error);
      });
  };

  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="mb-6 font-serif text-3xl">Let's test AZ!</h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-10 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <label htmlFor="profile_id" className="block font-bold leading-6 text-gray-900">
                profile id *
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="profile_id"
                  id="profile_id"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="profile_id-error"
                  {...register("profile_id", { required: true })}
                />
              </div>
              {errors.profile_id && (
                <p className="mt-2 text-sm text-red-600" id="profile_id-error">
                  profile id is required.
                </p>
              )}
            </div>
            <div className="pb-6"></div>
            <div className="flex flex-col justify-center">
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                get eligibility
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
