import {
  container2,
  question,
  inputWrapper,
  questionMain,
  textareaClass,
  errorClass,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { RadioPS } from "./radioPS";
import { EDUCATION } from "./overview";
import { handleError } from "../../../utils/handleError";
import { handleSave } from "./handleSave";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";

// additional
const DEGREE_MAJOR = "degree_major";
const PROFESSIONAL_LICENSE = "professional_license";
const NEGATIVE_IMPACT = "negative_impact";

export function Education() {
  const navigate = useNavigate();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [EDUCATION]: "",
    },
  });
  const current = watch(EDUCATION);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(EDUCATION)
      .then((response) => {
        response.forEach((row) => {
          setValue(EDUCATION, row.selection);
          if (row.additional_json) {
            if (row.additional_json[DEGREE_MAJOR]) {
              setValue(DEGREE_MAJOR, row.additional_json[DEGREE_MAJOR]);
            }
            if (row.additional_json[PROFESSIONAL_LICENSE]) {
              setValue(PROFESSIONAL_LICENSE, row.additional_json[PROFESSIONAL_LICENSE]);
            }
            if (row.additional_json[NEGATIVE_IMPACT]) {
              setValue(NEGATIVE_IMPACT, row.additional_json[NEGATIVE_IMPACT]);
            }
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  const handlePreSave = (path) => {
    const values = getValues();
    if (values[EDUCATION] !== "") {
      // eslint-disable-next-line no-console
      console.log("values", values);
      setGenericErrorMessage("");
      const newDetails = [
        {
          selection: values[EDUCATION],
          additional_json: {
            [DEGREE_MAJOR]: values[DEGREE_MAJOR],
            [PROFESSIONAL_LICENSE]: values[PROFESSIONAL_LICENSE],
            [NEGATIVE_IMPACT]: values[NEGATIVE_IMPACT],
          },
        },
      ];
      handleSave(EDUCATION, newDetails, navigate, path);
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/employment";
    handlePreSave(path);
  };
  const handleNextClick = () => {
    const path = "/statement/volunteer-work";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="heading">
        <div className={container2}>
          <div className={questionMain}>What is your highest level of education?</div>
          <div className="pb-4">
            <RadioPS
              name={EDUCATION}
              answer="some high school"
              label="some high school"
              register={register}
              {...register(EDUCATION)}
              current={current}
            />
            <RadioPS
              name={EDUCATION}
              answer="high school diploma or GED"
              label="high school diploma or GED"
              register={register}
              {...register(EDUCATION)}
              current={current}
            />
            <RadioPS
              name={EDUCATION}
              answer="some college"
              label="some college"
              register={register}
              {...register(EDUCATION)}
              current={current}
            />
            <RadioPS
              name={EDUCATION}
              answer="associate's degree"
              label="associate's degree"
              register={register}
              {...register(EDUCATION)}
              current={current}
            />
            <RadioPS
              name={EDUCATION}
              answer="bachelor's degree"
              label="bachelor's degree"
              register={register}
              {...register(EDUCATION)}
              current={current}
            />
            <RadioPS
              name={EDUCATION}
              answer="graduate degree"
              label="graduate degree"
              register={register}
              {...register(EDUCATION)}
              current={current}
            />
          </div>
          {current.includes("degree") && (
            <div>
              <h2 className={question}>
                What was your field of study? (If you have multiple degrees, please list them here)
              </h2>
              <div className={inputWrapper}>
                <textarea
                  type="text"
                  name={DEGREE_MAJOR}
                  id={DEGREE_MAJOR}
                  className={textareaClass}
                  rows="3"
                  {...register(DEGREE_MAJOR)}
                ></textarea>
              </div>
            </div>
          )}
          <div>
            <h2 className={question}>
              If you have any professional licenses, please list them here.
            </h2>
            <div className={inputWrapper}>
              <textarea
                type="text"
                name={PROFESSIONAL_LICENSE}
                id={PROFESSIONAL_LICENSE}
                className={textareaClass}
                rows="3"
                {...register(PROFESSIONAL_LICENSE)}
              ></textarea>
            </div>
          </div>
          <div>
            <h2 className={question}>
              If your record has had a negative impact on your education, please explain:
            </h2>
            <div className={inputWrapper}>
              <textarea
                type="text"
                name={NEGATIVE_IMPACT}
                id={NEGATIVE_IMPACT}
                className={textareaClass}
                rows="4"
                {...register(NEGATIVE_IMPACT)}
              ></textarea>
            </div>
          </div>
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext handleBackClick={handleBackClick} handleNextClick={handleNextClick} />
        </div>
      </section>
    </>
  );
}
