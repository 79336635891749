import * as AlertDialog from "@radix-ui/react-alert-dialog";

export function Step1({ next }) {
  return (
    <div className="p-6">
      <AlertDialog.Description className="mb-8">
        Please give us more information about your out-of-state or federal cases so we can determine
        the best way to help you.
      </AlertDialog.Description>
      <button
        type="button"
        className="w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
        onClick={next}
      >
        next
      </button>
    </div>
  );
}
