import { useState } from "react";
import { Complete, Court, Drafting, Prosecutor } from "./icons";
import { ShowContent } from "./ShowContent";
import {
  draftingFiling,
  motionComplete,
  prosecutorReview,
  waitingForCourt,
} from "./DescriptionsExpungement";
import { STATIC } from "./ProgressStepperExpungement";
import { actionEntity, content, LI, row } from "./ProcessExpungement";
import {
  getOtherMotionCompleteDescription,
  getOtherMotionDraftingDescription,
  getOtherMotionProsecutorReviewDescription,
  getOtherMotionWaitingForCourtDescription,
} from "./DescriptionsOtherMotions";

export const ProcessOther = () => {
  const [isShowingDraft, setIsShowingDraft] = useState(false);
  const [isShowingProsecutor, setIsShowingProsecutor] = useState(false);
  const [isShowingCourt, setIsShowingCourt] = useState(false);
  const [isShowingComplete, setIsShowingComplete] = useState(false);

  return (
    <>
      <ol className="mt-3">
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Drafting stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold">
                {draftingFiling} <div className={actionEntity}> (rasa)</div>
              </div>
              <ShowContent isShowing={isShowingDraft} setIsShowing={setIsShowingDraft} />
            </div>
          </div>
          {isShowingDraft && (
            <div className={content}>{getOtherMotionDraftingDescription(false)}</div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Prosecutor stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold">
                {prosecutorReview} <div className={actionEntity}> (government)</div>
              </div>
              <ShowContent isShowing={isShowingProsecutor} setIsShowing={setIsShowingProsecutor} />
            </div>
          </div>
          {isShowingProsecutor && (
            <div className={content}>{getOtherMotionProsecutorReviewDescription(false, null)}</div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Court stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold">
                {waitingForCourt} <div className={actionEntity}> (government)</div>
              </div>
              <ShowContent isShowing={isShowingCourt} setIsShowing={setIsShowingCourt} />
            </div>
          </div>
          {isShowingCourt && (
            <div className={content}>
              {getOtherMotionWaitingForCourtDescription(false, false, false)}
            </div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Complete stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold">
                {motionComplete} <div className={actionEntity}> (rasa)</div>
              </div>
              <ShowContent isShowing={isShowingComplete} setIsShowing={setIsShowingComplete} />
            </div>
          </div>
          {isShowingComplete && (
            <div className={content}>{getOtherMotionCompleteDescription(false)}</div>
          )}
        </li>
      </ol>
    </>
  );
};
