import RasaLogo from "@images/rasa-logo.svg";
import { useForm, useFieldArray } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { ConfirmAliasesModal } from "./ConfirmAliasesModal";
import { errorUnderInput, inputLabel, inputWrapper } from "../../../../../constants/classnames";
import { updatePerson } from "../../../../../api/secureApi";
import { handleError } from "../../../../../utils/handleError";

export function Aliases() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // eslint-disable-next-line no-console
  console.log("state in aliases---", state);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [dataForConfirm, setDataForConfirm] = useState([]);
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      aliases: [
        {
          first_name: "",
          last_name: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({ control, name: "aliases" });

  const handleNextClick = () => {
    const aliases = getValues("aliases");
    if (aliases[0].first_name !== "" && aliases[0].last_name !== "") {
      setDataForConfirm(aliases);
      setOpenConfirm(true);
    }
  };

  const handleNoAliasesClick = () => {
    navigate("/register/address", { state: { ...state } });
  };

  const onSubmitAfterConfirm = () => {
    const aliasesData = getValues("aliases");
    // eslint-disable-next-line no-console
    console.log("aliasesData....", aliasesData);
    const aliasesToSubmit = [];
    aliasesData.forEach((alias) => {
      aliasesToSubmit.push({
        first_name: alias.first_name.trim(),
        middle_name: alias.middle_name.trim(),
        last_name: alias.last_name.trim(),
      });
    });

    const dataToSubmit = {
      profile_id: state.profile_id,
      aliases: aliasesToSubmit,
    };
    // eslint-disable-next-line no-console
    console.log("dataToSubmit in aliases info", dataToSubmit);
    updatePerson(dataToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in aliases info", response);
        navigate("/register/address", { state: { ...response } });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in aliases info", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="form">
        <form onSubmit={handleSubmit(handleNextClick)}>
          <div className="container mx-auto mb-16 mt-6 flex max-w-3xl flex-col px-4">
            <div className="flex max-w-3xl flex-col pb-8">
              <h2 className="mb-4 font-serif text-2xl">do you have any other names?</h2>
              <div className="mb-4">
                In order to find all your records, we need to search all of your possible names.
              </div>
              <div className="mb-4">
                Please include:
                <ul className="ml-6 list-disc">
                  <li>nicknames</li>
                  <li>maiden names</li>
                  <li>married names</li>
                  <li>aliases</li>
                  <li>common misspellings of your name</li>
                  <li>names you know you have records under</li>
                </ul>
              </div>
              <div className="mb-4 rounded bg-pink-100 p-2">
                For example, if your legal name is <b>Jon Smyth</b> you might enter these other
                names:
                <ul className="ml-6 list-disc">
                  <li>John Smyth</li>
                  <li>Jon Smith</li>
                  <li>John Smith</li>
                </ul>
              </div>
              <div className="mb-4">
                Please click the "add another name" button if you need to add more than one other
                name.
              </div>
              <ul>
                {fields.map((item, index) => {
                  return (
                    <li key={item.id}>
                      {/* card */}
                      <div className="mb-6 overflow-hidden rounded-md border-2 border-gray-200 px-6 pb-4">
                        <div className="-ml-3 pb-2 pt-1">additional name {index + 1}</div>
                        <div className="pb-4">
                          <label htmlFor={`first_name-alias-${item.id}`} className={inputLabel}>
                            first name *
                          </label>
                          <div className={inputWrapper}>
                            <input
                              type="text"
                              name={`first_name-alias-${item.id}`}
                              id={`first_name-alias-${item.id}`}
                              className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                              ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                              placeholder="Jon"
                              defaultValue=""
                              aria-invalid="false"
                              aria-describedby={`first_name-alias-${item.id}-error`}
                              {...register(`aliases.${index}.first_name`, { required: true })}
                            />
                          </div>
                          {errors.aliases && errors.aliases[index]?.first_name && (
                            <div
                              className={errorUnderInput}
                              id={`first_name-alias-${item.id}-error`}
                            >
                              First name is required.
                            </div>
                          )}
                        </div>
                        <div className="pb-4">
                          <label htmlFor={`middle_name-alias-${item.id}`} className={inputLabel}>
                            middle name
                          </label>
                          <div className={inputWrapper}>
                            <input
                              type="text"
                              name={`middle_name-alias-${item.id}`}
                              id={`middle_name-alias-${item.id}`}
                              className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                              ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                              placeholder="Paul"
                              defaultValue=""
                              aria-invalid="false"
                              aria-describedby={`middle_name-alias-${item.id}-error`}
                              {...register(`aliases.${index}.middle_name`)}
                            />
                          </div>
                        </div>
                        {/* alias lastname*/}
                        <div className="pb-4">
                          <label htmlFor={`last_name-alias-${item.id}`} className={inputLabel}>
                            last name *
                          </label>
                          <div className={inputWrapper}>
                            <input
                              type="text"
                              name={`last_name-alias-${item.id}`}
                              id={`last_name-alias-${item.id}`}
                              className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                              ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                              placeholder="Smith"
                              defaultValue=""
                              aria-invalid="false"
                              aria-describedby={`last_name-alias-${item.id}-error`}
                              {...register(`aliases.${index}.last_name`, { required: true })}
                            />
                          </div>
                          {errors.aliases && errors.aliases[index]?.last_name && (
                            <div
                              className={errorUnderInput}
                              id={`last_name-alias-${item.id}-error`}
                            >
                              Last name is required.
                            </div>
                          )}
                        </div>
                        {/* remove alias button */}
                        <div className="flex justify-end">
                          <button
                            type="button"
                            className="mr-2 inline-flex items-center justify-center text-rasapink transition-colors duration-150 hover:shadow-xl"
                            onClick={() => remove(index)}
                          >
                            <MinusCircleIcon className="h-4 w-4 text-rasapink" />
                            remove name
                          </button>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div>
                <button
                  type="button"
                  className="mr-2 inline-flex items-center justify-center rounded-full border border-rasapink px-2 py-1 text-rasapink transition-colors duration-150 hover:shadow-xl"
                  onClick={() => append({ first_name: "", last_name: "" })}
                >
                  <PlusCircleIcon className="h-4 w-4 text-rasapink" />
                  add another name
                </button>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2 text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                // onClick={handleNextClick}
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 text-sm font-bold text-white hover:shadow-xl"
              >
                save names
              </button>
              <button
                type="button"
                onClick={handleNoAliasesClick}
                className="w-full rounded-full bg-white p-4 text-sm font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-rasapink hover:shadow-xl"
              >
                I don't have other names
              </button>
            </div>
          </div>
          <ConfirmAliasesModal
            open={openConfirm}
            setOpen={setOpenConfirm}
            onSubmit={onSubmitAfterConfirm}
            data={dataForConfirm}
          />
        </form>
      </section>
    </>
  );
}
