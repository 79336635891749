import { ChevronRightIcon } from "@heroicons/react/20/solid";

const buttonClass =
  "mb-4 w-full rounded-full bg-rasapink py-2 pl-6 pr-3 font-semibold text-white hover:shadow-xl";

export const ButtonNext = ({ handleClick }) => {
  return (
    <div>
      <button type="button" className={buttonClass} onClick={handleClick}>
        <div className="flex justify-center">
          <div>next</div>
          <ChevronRightIcon className="-mt-0.5 h-7 text-white" />
        </div>
      </button>
    </div>
  );
};
