import { create } from "zustand";

const useRasaStore = create((set) => ({
  user: {
    first: "",
    last: "",
    birth_date: "",
  },
  openCases: false,
  probationOrParole: false,
  setUser: (user) => set(() => ({ user })),
  setOpenCases: (openCases) => set(() => ({ openCases })),
  setProbationOrParole: (probationOrParole) => set(() => ({ probationOrParole })),
}));

// convenience selectors
const userSelector = (state) => state.user;

export { useRasaStore, userSelector };
