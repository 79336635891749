import * as AlertDialog from "@radix-ui/react-alert-dialog";
import {
  primaryButtonSmall2,
  secondaryButtonSmall,
  tertiaryButtonSmall,
} from "../../../../../constants/classnames";
import { Link } from "react-router-dom";

export function EmailInUseModal({ open, setOpen, email }) {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[75vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[80vh] flex-col">
            <AlertDialog.Title className="my-6 px-10 text-center font-serif text-xl">
              <div className="mb-2">Is</div>
              <div className="mb-2">{email ? <u>{email}</u> : "this"}</div>
              <div>your email?</div>
            </AlertDialog.Title>
            <div className="mb-6 px-6 text-lg">
              <div className="container mx-auto mb-16 mt-2 flex max-w-3xl flex-col px-4">
                <div className="flex flex-col justify-center">
                  <Link to="/login" state={{ email: email }}>
                    <button type="button" className={primaryButtonSmall2}>
                      yes, <span className="tracking-tighter">take me to login</span>
                    </button>
                  </Link>
                  <Link to="/forgot" state={{ email: email }}>
                    <button type="button" className={tertiaryButtonSmall}>
                      yes, <span className="tracking-tighter">but I forgot my password</span>
                    </button>
                  </Link>
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className={secondaryButtonSmall}
                  >
                    no, that's not me
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
