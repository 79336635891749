export const formatBirthDateFromDashToSlash = (birthDate) => {
  if (birthDate && birthDate.includes("-")) {
    // yyyy-mm-dd
    const d = birthDate.split("-");
    const year = d[0];
    const month = d[1];
    const day = d[2];

    return [month, day, year].join("/");
  } else {
    return birthDate;
  }
};
