export const AutoExpungementNote = () => {
  return (
    <>
      <div className="mt-6 rounded-md bg-amber-100 p-2">
        <b>Note:</b> Utah's automatic expungement program has a long back log and has recently been
        put on pause. To learn more about the 2024 law that paused the program,{" "}
        <span className="text-rasapink underline">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.rasa-legal.com/blog/changes-to-expungement-in-utah/"
          >
            click here
          </a>
        </span>
      </div>
    </>
  );
};
