import RasaLogo from "@images/rasa-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

export function AccountCreated() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleClick = () => {
    // todo: profileId will come from backend when account is created
    navigate("/register/open-cases", { state });
  };

  return (
    <>
      {/*logo and nav header*/}
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="success">
        <div className="flex items-center justify-center bg-green-100 py-2 font-bold">
          <span>account created </span>
          <CheckCircleIcon className="h-5 text-green-400" />
        </div>
      </section>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">thank you for creating an account!</h2>
        </div>
      </section>
      <section id="bodyText">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <p>
            Next, we will ask you a few screening questions to determine the best way to help you.
          </p>
        </div>
      </section>
      <section id="button">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col justify-center px-4">
          <button
            type="button"
            onClick={handleClick}
            className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
          >
            continue
          </button>
        </div>
      </section>
    </>
  );
}
