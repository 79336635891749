import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const StatusModal = ({ open, setOpen, title = "status", description = "description" }) => {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-fit flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <AlertDialog.Description className="mb-8 px-6">
              <section id="form">
                <form>
                  <div className="container mx-auto mb-8 flex max-w-3xl flex-col px-4">
                    <div className="flex flex-col justify-center">
                      <div className="font-serif text-lg">{title}</div>
                      <div>{description}</div>
                    </div>
                  </div>
                </form>
              </section>
            </AlertDialog.Description>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};
