import {
  container2,
  errorClass,
  input,
  inputWrapperNoLabel,
  questionMain,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { RadioPS } from "./radioPS";
import { useForm } from "react-hook-form";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { useEffect, useRef, useState } from "react";
import { handleSave } from "./handleSave";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { handleError } from "../../../utils/handleError";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";

// selection
const PRONOUNS = "pronouns";

// additional
const OTHER = "other";
const MALE = "he/him";
const FEMALE = "she/her";
const answers = [MALE, FEMALE, "they/them", "other"];

export function Pronouns() {
  const navigate = useNavigate();
  const ref0 = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [PRONOUNS]: "",
    },
  });
  const current = watch(PRONOUNS);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(PRONOUNS)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in pronouns", response);
        if (response.length === 0) {
          const title = localStorage.getItem("title");
          if (title) {
            if (title.toLowerCase() === "mr" || title.toLowerCase() === "mr.") {
              // gotta account for something stupid in db by checking both mr and mr.
              setValue(PRONOUNS, MALE);
            } else if (title.toLowerCase() === "ms" || title.toLowerCase() === "mrs") {
              setValue(PRONOUNS, FEMALE);
            }
            // after we set it here, it'll be saved to person_answer on navigate
            // and we're done with local
            localStorage.setItem("title", "");
          }
        }
        response.forEach((row) => {
          setValue(PRONOUNS, row.selection);
          // setCurrent(row.selection);
          if (row.additional_json && row.additional_json[OTHER]) {
            setValue(OTHER, row.additional_json[OTHER]);
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  const handlePreSave = (path) => {
    const pronouns = getValues(PRONOUNS);
    if (pronouns) {
      const other = getValues(OTHER);
      if (pronouns || other) {
        const newDetails = [
          {
            selection: pronouns,
            additional_json: {
              other: other,
            },
          },
        ];
        handleSave(PRONOUNS, newDetails, navigate, path);
      }
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/reasons";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/employment";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="menu">
        <div className={container2}>
          <div className={questionMain}>
            What pronouns would you like us to use in your court documents?
          </div>
          <div className="pb-4">
            <RadioPS
              innerRef={ref0}
              name={PRONOUNS}
              answer={answers[0]}
              label={answers[0]}
              register={register}
              {...register(PRONOUNS)}
              current={current}
            />
            <RadioPS
              innerRef={ref1}
              name={PRONOUNS}
              answer={answers[1]}
              label={answers[1]}
              register={register}
              {...register(PRONOUNS)}
              current={current}
            />
            <RadioPS
              innerRef={ref2}
              name={PRONOUNS}
              answer={answers[2]}
              label={answers[2]}
              register={register}
              {...register(PRONOUNS)}
              current={current}
            />
            <RadioPS
              innerRef={ref3}
              name={PRONOUNS}
              answer={answers[3]}
              label={answers[3]}
              register={register}
              {...register(PRONOUNS)}
              current={current}
            />
            {getValues(PRONOUNS) === OTHER && (
              <div className={inputWrapperNoLabel}>
                <input
                  placeholder="other"
                  type="text"
                  name={OTHER}
                  id={OTHER}
                  className={input}
                  {...register(OTHER)}
                />
              </div>
            )}
          </div>
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext handleBackClick={handleBackClick} handleNextClick={handleNextClick} />
        </div>
      </section>
    </>
  );
}
