import RasaLogo from "@images/rasa-logo.svg";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { errorUnderInput, primaryButton, secondaryButton } from "../../../constants/classnames";
import { forgotPassword } from "../../../api/secureApi";
import { genericMessage } from "../../../utils/handleError";
import { loading } from "../../../components/loading";

export function ForgotPassword() {
  const location = useLocation();
  const email = location?.state?.email || "";
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = () => {
    setIsLoading(true);
    const email = getValues("email");
    forgotPassword({ username: email })
      .then((response) => {
        setDisable(true);
        setSuccessMessage(response.detail);
        setGenericErrorMessage("");
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error from reset password:", error);
        setGenericErrorMessage(genericMessage);
        setIsLoading(false);
      });
  };

  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          {/*logo*/}
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="mb-6 text-center font-serif text-2xl">request password reset email</h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto mb-16 mt-10 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <label htmlFor="email" className="block font-bold leading-6 text-gray-900">
                email *
              </label>
              <div className="relative rounded-md shadow-sm">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  placeholder="you@example.com"
                  defaultValue={email || ""}
                  aria-invalid="false"
                  aria-describedby="email-error"
                  {...register("email", { required: true, pattern: /^\S+@\S+\.\S+$/ })}
                />
              </div>
              {errors.email && (
                <p className={errorUnderInput} id="email-error">
                  Email address is required.
                </p>
              )}
            </div>
            <div className="flex flex-col justify-center">
              {successMessage ? (
                <div className="my-6 text-center font-bold text-green-600" id="email-error">
                  <div>{successMessage}</div>
                  <div>Please check your email for the reset link</div>
                </div>
              ) : (
                <button type="submit" className={primaryButton} disabled={disable}>
                  {isLoading ? loading : "send password reset email"}
                </button>
              )}
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <Link to="/login">
                <button type="button" className={secondaryButton}>
                  go to login page
                </button>
              </Link>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
