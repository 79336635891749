import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Error } from "../../../../components/error";
import { updatePerson } from "../../../../api/secureApi";
import { MANDATORY_SURVEY } from "../../../../constants/surveyTypes";
import { handleError } from "../../../../utils/handleError";
import { errorUnderInput, questionMain3 } from "../../../../constants/classnames";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { STATE_LIST } from "../../../../constants/usStates";
import { WhyModal } from "../../../../components/alertModal";
import { getFeeWaiverEligibility } from "./utils.js";
import { vocUtm } from "../../../../constants/utmCodes";
import { InfoModal } from "../../../../components/alertModal/infoModal";
// Food stamps (SNAP), Medicaid, SSI, FEP or TANF
// NOTE: UTAH only for now

export function GovernmentBenefits() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [openWhy, setOpenWhy] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    const number_of_dependents = currentMandatorySurvey.answers.number_of_dependents;
    const income_range = currentMandatorySurvey.answers.income_range;
    const government_benefit = data.government_benefit;
    const is_fee_waiver_eligible = getFeeWaiverEligibility(
      number_of_dependents,
      income_range,
      government_benefit,
    );

    const stateToSubmit = {
      profile_id: state.profile_id,
      surveys: [
        {
          type: MANDATORY_SURVEY,
          answers: {
            race: currentMandatorySurvey.answers.race,
            race_other: currentMandatorySurvey.answers.race_other || "", // optional field
            ethnicity: currentMandatorySurvey.answers.ethnicity,
            open_cases: currentMandatorySurvey.answers.open_cases,
            on_probation_or_parole: currentMandatorySurvey.answers.on_probation_or_parole,
            other_convictions: currentMandatorySurvey.answers.other_convictions,
            other_convictions_list: currentMandatorySurvey.answers.other_convictions_list || [], // optional field
            expunged_before: currentMandatorySurvey.answers.expunged_before,
            expunged_before_story: currentMandatorySurvey.answers.expunged_before_story || "", // optional field
            number_of_dependents: number_of_dependents,
            income_range: income_range,
            income_range_fpl: currentMandatorySurvey.answers.income_range_fpl,
            government_benefit: government_benefit,
            is_fee_waiver_eligible: is_fee_waiver_eligible,
          },
        },
      ],
    };
    // eslint-disable-next-line no-console
    console.log(stateToSubmit);
    updatePerson(stateToSubmit)
      .then((response) => {
        if (response.utm_code?.includes(vocUtm)) {
          navigate("/register/voc-case-manager", { state: response });
        } else {
          navigate("/register/court-data", { state: response });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in gov bene", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={questionMain3}>
            government benefits
            <InformationCircleIcon
              className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
              onClick={() => setOpenWhy(true)}
            />
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto mb-10 mt-6 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <label htmlFor="govBenefits" className="block font-bold leading-6 text-gray-900">
                do you receive any of these government benefits?
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                  <input
                    type="radio"
                    name="government_benefit-radio"
                    id="government_benefit_food_stamps"
                    className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                    value="Food stamps (SNAP)"
                    aria-invalid="false"
                    aria-describedby="government_benefit-error"
                    {...register("government_benefit", { required: true })}
                  />
                  <label
                    htmlFor="government_benefit_food_stamps"
                    className="block w-full cursor-pointer py-4 pl-2 font-bold leading-6 text-gray-900"
                  >
                    Food stamps (SNAP)
                  </label>
                </div>
                <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                  <input
                    type="radio"
                    name="government_benefit-radio"
                    id="government_benefit_medicaid"
                    className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                    value="Medicaid"
                    aria-invalid="false"
                    aria-describedby="government_benefit-error"
                    {...register("government_benefit", { required: true })}
                  />
                  <label
                    htmlFor="government_benefit_medicaid"
                    className="block w-full cursor-pointer py-4 pl-2 font-bold leading-6 text-gray-900"
                  >
                    Medicaid
                  </label>
                </div>
                <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                  <input
                    type="radio"
                    name="government_benefit-radio"
                    id="government_benefit_ssi"
                    className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                    value="SSI"
                    aria-invalid="false"
                    aria-describedby="government_benefit-error"
                    {...register("government_benefit", { required: true })}
                  />
                  <label
                    htmlFor="government_benefit_ssi"
                    className="block w-full cursor-pointer py-4 pl-2 font-bold leading-5 tracking-tighter text-gray-900"
                  >
                    Supplemental Security Income (SSI)
                    <InformationCircleIcon
                      className="relative -top-1 inline h-4 cursor-pointer text-rasapink"
                      onClick={() => setOpenInfo(true)}
                    />
                  </label>
                </div>
                <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                  <input
                    type="radio"
                    name="government_benefit-radio"
                    id="government_benefit_fep"
                    className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                    value="FEP or TANF"
                    aria-invalid="false"
                    aria-describedby="government_benefit-error"
                    {...register("government_benefit", { required: true })}
                  />
                  <label
                    htmlFor="government_benefit_fep"
                    className="block w-full cursor-pointer py-4 pl-2 font-bold leading-6 text-gray-900"
                  >
                    FEP or TANF
                  </label>
                </div>
                <div className="relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500">
                  <input
                    type="radio"
                    name="government_benefit-radio"
                    id="government_benefit_no"
                    className="form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink"
                    value="no"
                    aria-invalid="false"
                    aria-describedby="government_benefit-error"
                    {...register("government_benefit", { required: true })}
                  />
                  <label
                    htmlFor="government_benefit_no"
                    className="block w-full cursor-pointer py-4 pl-2 font-bold leading-6 text-gray-900"
                  >
                    none of the above
                  </label>
                </div>
                {errors.government_benefit && (
                  <p className={errorUnderInput} id="government_benefit-error">
                    Answer is required.
                  </p>
                )}
              </div>
              <Error errors={errors} name="governmentBenefits" />
            </div>
            <WhyModal
              open={openWhy}
              setOpen={setOpenWhy}
              answer={`This data helps us determine whether you might qualify for legal aid under ${
                STATE_LIST[state.us_state]
              } law.`}
            />
            <InfoModal
              open={openInfo}
              setOpen={setOpenInfo}
              answer="Supplemental Security Income (SSI) is NOT the same as Social Security Retirement or Disability SSDI."
            />
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 text-sm font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
