import { radio, radioLabel, radioWrapper } from "../../../constants/classnames";
import { forwardRef } from "react";

// eslint-disable-next-line react/display-name
export const RadioPS = forwardRef(({ name, answer, label, register, current }, innerRef) => {
  const registeredName = register(name);

  const handleChange = () => {
    registeredName.onChange({
      target: {
        name: name,
        value: answer,
      },
    });
  };

  return (
    <div className={radioWrapper}>
      <input
        ref={innerRef}
        type="radio"
        name={`${name}Radio`}
        id={`${name}-${answer}`}
        className={radio}
        value={answer}
        aria-invalid="false"
        aria-describedby={`${name}-error`}
        onChange={handleChange}
        checked={current === answer}
      />
      <label htmlFor={`${name}-${answer}`} className={radioLabel}>
        {label}
      </label>
    </div>
  );
});
