import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
const chevronButton = "-left-5 top-4 h-7 text-gray-600";
export const ShowContent = ({ isShowing, setIsShowing }) => {
  if (isShowing) {
    return (
      <div>
        <ChevronUpIcon className={chevronButton} onClick={() => setIsShowing(false)} />
      </div>
    );
  } else {
    return (
      <div>
        <ChevronDownIcon className={chevronButton} onClick={() => setIsShowing(true)} />
      </div>
    );
  }
};
