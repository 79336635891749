import { useState } from "react";
import { AlertModal } from "@components";
import { Step1 } from "./step1";
import { Step2Form } from "./step2Form";

export function OtherConvictionsModal({ open, setOpen, done, usState }) {
  const [step, setStep] = useState(1);
  return (
    <AlertModal open={open} className="p-0">
      {
        {
          1: <Step1 next={() => setStep(2)} />,
          2: (
            <Step2Form
              next={(data) => {
                setStep(1);
                setOpen(false);
                done(data);
              }}
              usState={usState}
            />
          ),
        }[step]
      }
    </AlertModal>
  );
}
