import { createRoot } from "react-dom/client";
import "@styles/main.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RouterProvider } from "react-router-dom";
import { router } from "@app/routes/router";

const root = createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

const render = () => {
  root.render(
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>,
  );
};

// Enable Webpack hot module replacement
if (module.hot) {
  module.hot.accept("./app", () => {
    render();
  });

  window.addEventListener("message", (e) => {
    if (process.env.NODE_ENV !== "production" && e.data && e.data.type === "webpackInvalid") {
      // eslint-disable-next-line
      console.clear();
    }
  });
}

render();
