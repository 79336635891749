const capLetterAfterSlash = (text) => {
  const textArray = text.split(" ");
  let newText = text;

  for (let word of textArray) {
    if (word.includes("/")) {
      const wordContainingSlash = word;
      const newWords = word.split("/");
      const capWords = newWords.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      const capWordsWithSlash = capWords.join("/ ");
      newText = newText.replace(wordContainingSlash, capWordsWithSlash);
    }
  }

  return newText;
};

export const toTitleCase = (s) => {
  function replFunc(match) {
    return match.charAt(0).toUpperCase() + match.slice(1);
  }

  // Replace words with title case
  let text = s.replace(/([^\W_]+[^\s-]*)/g, replFunc);

  // Words that should remain lowercase
  const lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  lowers.forEach((word) => {
    const wordPattern = new RegExp(`\\b${word}\\b`, "gi");
    text = text.replace(wordPattern, word.toLowerCase());
  });

  // Words that should remain uppercase
  const uppers = ["Id", "Tv", "Alc/cs", "Cs", "C/s", "Dui", "Ap&p", "Il", "Ma", "Mb", "Mc"];
  uppers.forEach((word) => {
    const wordPattern = new RegExp(`\\b${word}\\b`, "g");
    text = text.replace(wordPattern, word.toUpperCase());
  });

  text = capLetterAfterSlash(text);

  return text;
};
