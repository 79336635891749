import RasaLogo from "@images/rasa-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { flexCenter, logo, menu, menuButtonClass } from "../../constants/classnames";
import { useEffect, useState } from "react";
import { UT } from "../../constants/usStates";
import { getStatus } from "../../api/secureApi";
import { EXPUNGEMENT } from "./matterStatus/constants";
import { bciAppPhase, needPS } from "./matterStatus/utils";

export const cleanLocalStorage = () => {
  localStorage.setItem("token", "");
  localStorage.setItem("profile_id", "");
  localStorage.setItem("us_state", "");
  localStorage.setItem("email", "");
  localStorage.setItem("show_clio_link", "");
};

const MenuButton = ({ onClick, title }) => {
  return (
    <div className="text-center">
      <button type="button" className={menuButtonClass} onClick={onClick}>
        {title}
      </button>
    </div>
  );
};

export function Menu() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [hasUtahMatters, setHasUtahMatters] = useState(false);
  const [showClio, setShowClio] = useState(false);
  useEffect(() => {
    const us_state = localStorage.getItem("us_state");
    if (us_state === UT) {
      getStatus()
        .then((response) => {
          const matters = response?.matters;
          if (matters) {
            setHasUtahMatters(!!matters.length);
            const show_clio_link = localStorage.getItem("show_clio_link");
            setShowClio(show_clio_link === "true");
            if (!show_clio_link) {
              const showClioLink = matters.some((m) => {
                if (m.workflow && m.workflow_stage) {
                  if (m.legal_action_type === EXPUNGEMENT) {
                    return (
                      !m.workflow?.toLowerCase()?.includes("bci phase") ||
                      (!m.workflow_stage?.toLowerCase()?.includes(bciAppPhase) &&
                        !m.workflow_stage?.toLowerCase()?.includes(needPS))
                    );
                  } else {
                    return true;
                  }
                }
              });
              setShowClio(!!showClioLink);
              localStorage.setItem("show_clio_link", showClioLink);
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error counting matters", error);
        });
    }
  }, []);

  const goToResults = () => {
    navigate("/results", { state: state });
  };

  const goToMatterStatus = () => {
    navigate("/matter-status");
  };

  const goToClioPage = () => {
    navigate("/clio-page");
  };

  const goToPersonalStatementInfo = () => {
    navigate("/statement/intro");
  };

  const handleLogoutClick = () => {
    cleanLocalStorage();
    navigate("/login");
  };

  return (
    <>
      <div className="container relative mx-auto pb-6 pt-10">
        <div className={flexCenter}>
          <div className={logo}>
            <RasaLogo className="h-7" />
          </div>
        </div>
      </div>
      <div id="menu" className={menu}>
        <div>
          <MenuButton onClick={goToResults} title="eligibility results" />
          <MenuButton onClick={goToPersonalStatementInfo} title="personal statement questions" />
          {hasUtahMatters && (
            <>
              <MenuButton onClick={goToMatterStatus} title="your cases" />
              {showClio && <MenuButton onClick={goToClioPage} title="clio for clients" />}
            </>
          )}
          <MenuButton onClick={handleLogoutClick} title="log out" />
        </div>
      </div>
    </>
  );
}
