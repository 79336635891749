import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Radio } from "../../../../components/radio";
import { Error } from "../../../../components/error";
import { MANDATORY_SURVEY, OPTIONAL_SURVEY } from "../../../../constants/surveyTypes";
import { updatePerson } from "../../../../api/secureApi";
import { adjustState } from "./utils";
import { useEffect, useState } from "react";
import { handleError } from "../../../../utils/handleError";
import { container3 } from "../../../../constants/classnames";
import { getQuestionNumber } from "./questionNumbers";

export function HealthInsurance() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [number, setNumber] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  useEffect(() => {
    if (state) {
      setNumber(getQuestionNumber("health_insurance", state.utm_code));
    }
  }, [state]);

  const onSubmit = (data) => {
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    let currentOptionalSurvey = state.surveys.find((survey) => survey.type === OPTIONAL_SURVEY);
    if (currentOptionalSurvey) {
      currentOptionalSurvey.answers.health_insurance = data.health_insurance;
    } else {
      currentOptionalSurvey = {
        type: OPTIONAL_SURVEY,
        answers: {
          health_insurance: data.health_insurance,
        },
      };
    }

    const stateToSubmit = {
      profile_id: state.profile_id,
      surveys: [currentOptionalSurvey, currentMandatorySurvey],
    };
    adjustState(state, stateToSubmit);
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in health_insurance", stateToSubmit);

    updatePerson(stateToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in health_insurance", response);
        navigate("/register/denied-housing", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in health_insurance", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">{number} Do you currently have health insurance?</h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={container3}>
            <div className="pb-4">
              <Radio
                name="health_insurance"
                answer="yes"
                label="yes"
                register={register}
                {...register("health_insurance", { required: true })}
              />
              <Radio
                name="health_insurance"
                answer="no"
                label="no"
                register={register}
                {...register("health_insurance", { required: true })}
              />
              <Error errors={errors} name="health_insurance" />
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 text-sm font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
