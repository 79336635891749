import { useState } from "react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { CaseDetailsModalAZ } from "./CaseDetailsModalAZ";

export const CardAZ = ({
  data,
  openMessageUs,
  setOpenMessageUs,
  openCases,
  onProbationOrParole,
  receivedAnyMessage,
  hasFreshworks,
}) => {
  const [open, setOpen] = useState(false);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const dispositionDate = new Date(data.dispositionDate).toLocaleDateString("en-US", options);

  return (
    <div className="my-4 mr-4 rounded bg-white p-4">
      <h4 className="font-serif text-2xl tracking-tight">{data.violation}</h4>
      <h4 className="mb-6 font-serif text-lg tracking-tight">{data.caseNumber}</h4>
      <div className="mb-1 text-gray-500">{dispositionDate}</div>
      <div className="mb-1 text-gray-500">
        {data.severity} - {data.disposition}
      </div>
      {data.expungementEligible && (
        <div>
          <CheckCircleIcon className="float-left mr-1 h-6 text-green-500" />
          <span className="tracking-tighter">Expungement Eligible</span>
        </div>
      )}
      {data.sealingEligibleNow && !openCases && !onProbationOrParole && (
        <div>
          <CheckCircleIcon className="float-left mr-1 h-6 text-green-500" />
          <span className="tracking-tighter">Sealing Eligible Now</span>
        </div>
      )}
      {data.sealingEligibleLater && (
        <div>
          <ExclamationTriangleIcon className="float-left mr-1 h-6 text-orange-400" />
          <span className="tracking-tighter">
            Sealing Eligible{" "}
            {data.sealingEligibleDate && !openCases && !onProbationOrParole
              ? `on ${data.sealingEligibleDate}`
              : "Later"}
          </span>
        </div>
      )}
      {data.setAsideEligibleNow && (
        <div>
          <CheckCircleIcon className="float-left mr-1 h-6 text-green-500" />
          <span className="tracking-tighter">Set Aside Eligible Now</span>
        </div>
      )}
      {data.setAsideEligibleLater && (
        <div>
          <ExclamationTriangleIcon className="float-left mr-1 h-6 text-orange-400" />
          <span className="tracking-tighter">
            Set Aside Eligible{" "}
            {data.setAsideEligibleDate ? `on ${data.setAsideEligibleDate}` : "Later"}
          </span>
        </div>
      )}
      {data.secondChanceEligibleNow && (
        <div>
          <CheckCircleIcon className="float-left mr-1 h-6 text-green-500" />
          <span className="tracking-tighter">Certificate of 2nd Chance Eligible Now</span>
        </div>
      )}
      {data.secondChanceEligibleLater && (
        <div>
          <ExclamationTriangleIcon className="float-left mr-1 h-6 text-orange-400" />
          <span className="tracking-tighter">
            Certificate of Second Chance Eligible{" "}
            {data.secondChanceEligibleDate ? `on ${data.secondChanceEligibleDate}` : "Later"}
          </span>
        </div>
      )}
      {data.rightsRestorationEligibleNow && (
        <div>
          <CheckCircleIcon className="float-left mr-1 h-6 text-green-500" />
          <span className="tracking-tighter">Rights Restoration Eligible Now</span>
        </div>
      )}
      {data.rightsRestorationEligibleLater && (
        <div>
          <ExclamationTriangleIcon className="float-left mr-1 h-6 text-orange-400" />
          <span className="tracking-tighter">
            Rights Restoration{" "}
            {data.rightsRestorationEligibleDate
              ? `on ${data.rightsRestorationEligibleDate}`
              : "Later"}
          </span>
        </div>
      )}
      {data.notEligible && (
        <div>
          <XCircleIcon className="float-left mr-1 h-6 text-red-600" />
          <span className="tracking-tighter">Not eligible for clearing</span>
        </div>
      )}
      {data.needMoreInfo && (
        <div>
          <QuestionMarkCircleIcon className="float-left mr-1 h-6 text-rasapink" />
          <span className="tracking-tighter">
            We need more information to help you with this case
          </span>
        </div>
      )}
      <button
        type="button"
        className="my-4 w-full rounded-full border border-rasapink bg-white p-4 font-bold text-black hover:shadow-xl"
        onClick={() => setOpen(true)}
      >
        see record
      </button>
      <CaseDetailsModalAZ
        open={open}
        setOpen={setOpen}
        openMessageUs={openMessageUs}
        setOpenMessageUs={setOpenMessageUs}
        data={data}
        openCases={openCases}
        onProbationOrParole={onProbationOrParole}
        receivedAnyMessage={receivedAnyMessage}
        hasFreshworks={hasFreshworks}
      />
    </div>
  );
};

// <h3>We need more information to help you ----------------------</h3>
// <h3>Eligible for Sealing Now ----------------------</h3>
// <h3>Eligible for Sealing Later ----------------------</h3>
// <h3>Eligible for Set Aside ----------------------</h3>
// <h3>Eligible for Marijuana Expungement ----------------------</h3>
// <h3>Optional: certificate of second chance, rights restoration, etc.</h3>
