import RasaLogo from "@images/rasa-logo.svg";
import { Phone } from "react-telephone";
import { useForm } from "react-hook-form";
import { REFERRAL_SOURCE } from "../../../../constants/referalSource";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { updatePerson } from "../../../../api/secureApi";
import { getToken } from "../../../../api/noAuthApi";
import { handleError } from "../../../../utils/handleError";
import { errorUnderInput, inputLabel, inputWrapper } from "../../../../constants/classnames";
import { cleanLocalStorage } from "../../menu";

export function datePickerValidator(date) {
  return Object.keys(date).length && date.startDate !== null && date.endDate !== null;
}
export function ExtendedInfo() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // eslint-disable-next-line no-console
  console.log("state in ext---", state);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  useEffect(() => {
    const dataToSubmit = {
      username: state.email,
      password: state.password,
    };
    getToken(dataToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("token response----", response);
        localStorage.setItem("token", response.access_token);
        localStorage.setItem("profile_id", state.profile_id);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("token error----", error);
      });
  }, [state]);

  const onSubmit = (data) => {
    const dataToSubmit = {
      profile_id: state.profile_id,
      ...data,
    };
    // eslint-disable-next-line no-console
    console.log("dataToSubmit in ext info.......", dataToSubmit);
    navigate("/register/aliases", { state: dataToSubmit });
    updatePerson(dataToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in ext info", response);
        // DEV NOTE: DO NOT just send prev state here; don't pass on the pw
        navigate("/register/aliases", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in ext info", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  const handleLogoutClick = () => {
    cleanLocalStorage();
    navigate("/login");
  };

  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          {/*logo*/}
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto mb-16 mt-10 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <label htmlFor="phone" className={inputLabel}>
                phone *
              </label>
              <div className={inputWrapper}>
                <Phone defaultCountry="us">
                  <Phone.Number
                    type="tel"
                    name="phone"
                    id="phone"
                    className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                    placeholder="(555) 555-555"
                    aria-invalid="false"
                    aria-describedby="phone-error"
                    {...register("phone", {
                      required: true,
                      pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                    })}
                  />
                </Phone>
              </div>
              {errors.phone && (
                <div className={errorUnderInput} id="phone-error">
                  Phone number is required.
                </div>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="title" className={inputLabel}>
                preferred title *
              </label>
              <div className={inputWrapper}>
                <select
                  id="title"
                  name="title"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  {...register("title", { required: true })}
                >
                  <option value="" disabled />
                  <option value="Mr">Mr.</option>
                  <option value="Ms">Ms.</option>
                  <option value="Mrs">Mrs.</option>
                  <option value="Mx">Mx.</option>
                  <option value="other">Other</option>
                </select>
              </div>
              {errors.title && (
                <div className={errorUnderInput} id="ethnicity-error">
                  Title selection is required.
                </div>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="referral_source" className={inputLabel}>
                how did you hear about Rasa? *
              </label>
              <div className={inputWrapper}>
                <select
                  id="referral_source"
                  name="referral_source"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  onChangeCapture={(e) => {
                    if (e.target.value.toLowerCase().includes("specify")) {
                      setShowOtherInput(true);
                    } else {
                      setShowOtherInput(false);
                    }
                  }}
                  {...register("referral_source", { required: true })}
                >
                  {REFERRAL_SOURCE.map(({ value, text }, index) => (
                    <option key={value} value={value} disabled={index === 0} hidden={index === 0}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>
              {errors.referral_source && (
                <div className={errorUnderInput} id="referral_source-error">
                  Referral source is required.
                </div>
              )}
            </div>
            {showOtherInput && (
              <div className="pb-4">
                <label htmlFor="referral_other" className={inputLabel}>
                  please give more detail on how you found us *
                </label>
                <div className={inputWrapper}>
                  <input
                    type="text"
                    name="referral_other"
                    id="referral_other"
                    placeholder="example: Fox news"
                    className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                    defaultValue=""
                    aria-invalid="false"
                    aria-describedby="referral_other-error"
                    {...register("referral_other", { required: true })}
                  />
                </div>
                {errors.referral_other && (
                  <div className={errorUnderInput} id="referral_other-error">
                    Answer is required.
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                next
              </button>
              <Link onClick={() => handleLogoutClick()}>
                <button
                  type="button"
                  className="w-full rounded-full bg-white px-24 py-4 text-sm font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-rasapink hover:shadow-xl"
                >
                  log out
                </button>
              </Link>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
