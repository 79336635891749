import { useNavigate } from "react-router-dom";
import {
  header2,
  menu,
  menuButtonWrapper,
  menuProgressButton,
} from "../../../constants/classnames";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/outline";
import { getAnswersAll } from "../../../api/secureApi";
import { useEffect, useState } from "react";
import { handleError } from "../../../utils/handleError";

// questions
export const CIRCUMSTANCES = "circumstances";
export const PROBATION_COMPLIANCE = "probation_compliance";
export const BARRIERS = "barriers";
export const CLEARING_REASONS = "clearing_reasons";
export const PRONOUNS = "pronouns";
export const EMPLOYMENT = "employment";
export const EDUCATION = "education";
export const VOLUNTEER_WORK = "volunteer_work";
export const CHILDREN = "children";
export const GRANDCHILDREN = "grandchildren";
export const HOUSING = "housing";
export const LIFE_IMPROVEMENT = "life_improvement";
export const ADDITIONAL_INFO = "additional_info";

const getStatusIcon = (isDone) => {
  if (isDone) {
    return (
      <div className="m-2 flex rounded-full bg-green-100 px-2 pt-1">
        <div>
          <CheckCircleIcon className="mr-1 h-6 text-green-500" />
        </div>
        <div className="text-sm">done</div>
      </div>
    );
  } else {
    return (
      <div className="m-2 flex px-2 pt-1">
        <div>
          <MinusCircleIcon className="mr-1 h-6 text-red-500" />
        </div>
        <div className="text-sm">to do</div>
      </div>
    );
  }
};

export function Overview() {
  const navigate = useNavigate();
  const [statuses, setStatues] = useState([]);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  useEffect(() => {
    getAnswersAll()
      .then((response) => {
        const newStatues = [];
        response.forEach((row) => {
          newStatues.push(row.question);
        });
        // eslint-disable-next-line no-console
        console.log("response in get all", response);
        setStatues(newStatues);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting all answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [navigate]);

  // useEffect(() => {
  //   setProfileId(localStorage.getItem("profile_id"));
  // }, []);

  // const handleLogoutClick = () => {
  //   localStorage.setItem("token", "");
  //   localStorage.setItem("profile_id", "");
  //   navigate("/login");
  // };

  return (
    <>
      <section id="heading">
        <div className="container relative mx-auto pt-6">
          <h2 className={header2}>
            <div>overview</div>
          </h2>
          <div></div>
        </div>
      </section>
      <section id="menu" className={menu}>
        <div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/life-circumstances")}
              >
                life circumstances
              </button>
            </div>
            {getStatusIcon(statuses.includes(CIRCUMSTANCES))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/probation")}
              >
                probation
              </button>
            </div>
            {getStatusIcon(statuses.includes(PROBATION_COMPLIANCE))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/barriers")}
              >
                barriers
              </button>
            </div>
            {getStatusIcon(statuses.includes(BARRIERS))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/reasons")}
              >
                clearing reasons
              </button>
            </div>
            {getStatusIcon(statuses.includes(CLEARING_REASONS))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/pronouns")}
              >
                pronouns
              </button>
            </div>
            {getStatusIcon(statuses.includes(PRONOUNS))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/employment")}
              >
                employment
              </button>
            </div>
            {getStatusIcon(statuses.includes(EMPLOYMENT))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/education")}
              >
                education
              </button>
            </div>
            {getStatusIcon(statuses.includes(EDUCATION))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/volunteer-work")}
              >
                volunteer work
              </button>
            </div>
            {getStatusIcon(statuses.includes(VOLUNTEER_WORK))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/children")}
              >
                children
              </button>
            </div>
            {getStatusIcon(statuses.includes(CHILDREN))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/grandchildren")}
              >
                grandchildren
              </button>
            </div>
            {getStatusIcon(statuses.includes(GRANDCHILDREN))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/housing")}
              >
                housing
              </button>
            </div>
            {getStatusIcon(statuses.includes(HOUSING))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/life-improvement")}
              >
                life improvement
              </button>
            </div>
            {getStatusIcon(statuses.includes(LIFE_IMPROVEMENT))}
          </div>
          <div className={menuButtonWrapper}>
            <div style={{ flex: 2 }}>
              <button
                type="button"
                className={menuProgressButton}
                onClick={() => navigate("/statement/additional-info")}
              >
                additional info
              </button>
            </div>
            {getStatusIcon(statuses.includes(ADDITIONAL_INFO))}
          </div>
          {genericErrorMessage && (
            <div className="mb-2  text-center text-sm text-red-600" id="email-error">
              {genericErrorMessage}
            </div>
          )}
        </div>
      </section>
    </>
  );
}
