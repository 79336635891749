import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const noAuthInstance = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 30000,
});

export async function getToken(params) {
  const form = new FormData();
  form.append("username", params.username);
  form.append("password", params.password);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const { data } = await noAuthInstance.post("/authentication/token", form, config);
  return data;
}

export async function createPerson(params) {
  const { data } = await noAuthInstance.post("/v1/person/", params);
  return data;
}

export async function checkReferral(params) {
  const { data } = await noAuthInstance.get(`/v1/referral/${params.code}`);
  return data;
}
