import { useLocation, useNavigate } from "react-router-dom";
import { usePaymentInputs } from "react-payment-inputs";
import { Controller, useForm } from "react-hook-form";
import images from "react-payment-inputs/images";
import { getPrice, makePayment } from "../../../../api/secureApi";
import { useEffect, useState } from "react";
import { Error } from "../../../../components/error";
import { checkTokenExpired, genericMessage, handleError } from "../../../../utils/handleError";
import { CouponModal } from "./CouponModal";
import {
  errorUnderInput,
  inputLabel,
  inputLabelNoWrap,
  inputWrapper,
  primaryButton,
  questionMain,
  secondaryButtonNotFull,
} from "../../../../constants/classnames";
import { loading } from "../../../../components/loading";
import { REFERRALCLIENT } from "../../../../constants/utmCodes";

export const doGtag = (value) => {
  window.gtag("event", "conversion", {
    send_to: "AW-11013553756/5mzpCKKLmYIYENz81YMp",
    value: value,
    currency: "USD",
    transaction_id: "",
  });
  window.gtag("event", "payment", {
    event_category: "transaction",
    event_label: "app_payment",
    value: value,
  });
};

export const Payment = () => {
  const { state } = useLocation();
  // eslint-disable-next-line no-console
  console.log("state in payment", state);
  const navigate = useNavigate();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  const [couponResponse, setCouponResponse] = useState({});
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingContinue, setIsLoadingContinue] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("15");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ mode: "onBlur" });

  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } =
    usePaymentInputs();

  useEffect(() => {
    if (state && state.amount) {
      setPaymentAmount(state.amount);
    }
  }, [state]);

  const handleCouponClick = (code) => {
    setIsLoading(true);
    if (state && state.profile_id) {
      getPrice({ promotion_code: code.replaceAll(" ", "") })
        .then((response) => {
          setSuccessMessage("Your coupon has been applied!");
          setCouponErrorMessage("");
          let total = response.total.toString();
          if (total.length > 1) {
            total = total.slice(0, -2);
          }
          if (window.gtag) {
            doGtag(total);
          }
          setPaymentAmount(total);
          setCouponResponse(response);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error coupon", error);
          handleError(error, setCouponErrorMessage, navigate);
          setSuccessMessage("");
          setIsLoading(false);
        });
    } else {
      setGenericErrorMessage("An error has occurred.");
      setIsLoading(false);
    }
  };

  const continueToPayment = () => {
    setIsLoadingContinue(true);
    if (paymentAmount === "0") {
      const dataToSubmit = { promotion_code: couponResponse.promotion_code };
      submitPayment(dataToSubmit);
    } else {
      setOpen(false);
    }
  };

  const onSubmit = (data) => {
    let month = "";
    let year = "";
    if (data && data.expiration) {
      const expirationArray = data.expiration.split("/");
      month = expirationArray[0].trim();
      year = expirationArray[1].trim();
    }
    let promotion_code;
    if (couponResponse.promotion_code) {
      promotion_code = couponResponse.promotion_code;
    } else if (state?.has_peer_referrer) {
      promotion_code = REFERRALCLIENT;
    }

    const dataToSubmit = {
      name_on_card: data.name_on_card,
      card_number: data.card_number,
      expiration_month: month,
      expiration_year: year,
      security_code: data.security_code,
      promotion_code: promotion_code,
    };
    submitPayment(dataToSubmit);
  };

  const submitPayment = (dataToSubmit) => {
    setIsLoadingPayment(true);
    makePayment(dataToSubmit)
      .then((response) => {
        if (window.gtag) {
          doGtag(paymentAmount);
        }
        navigate("/register/verification", { state: response });
        setIsLoadingPayment(false);
        setIsLoading(false);
        setIsLoadingContinue(false);
        setOpen(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in payment", error);
        setIsLoadingPayment(false);
        setIsLoading(false);
        setIsLoadingContinue(false);
        setOpen(false);
        if (typeof error.response.data.detail === "string") {
          checkTokenExpired(error, navigate);
          if (error && error.response && error.response.data && error.response.data.detail) {
            if (error.response.data.detail === "Already paid") {
              navigate("/register/verification", { state: state });
            } else {
              setGenericErrorMessage(error.response.data.detail);
            }
          } else {
            setGenericErrorMessage(genericMessage);
          }
        }
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={questionMain}>coupon and payment</h2>
          <p>Complete payment to see records and eligibility</p>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-6 flex max-w-3xl flex-col rounded-md border-2 bg-gray-50 px-6 py-4">
            {successMessage ? (
              <div className="my-6 text-center font-bold text-green-600" id="email-error">
                <div>{successMessage}</div>
                <div>Your price is ${paymentAmount}</div>
              </div>
            ) : (
              <div className="text-center">
                <button
                  onClick={() => setOpen(true)}
                  type="button"
                  className={secondaryButtonNotFull}
                >
                  I have a coupon code
                </button>
              </div>
            )}
            <div className="pb-4">
              <label htmlFor="name_on_card" className={inputLabel}>
                full name on card *
              </label>
              <div className={inputWrapper}>
                <input
                  type="text"
                  name="name_on_card"
                  id="name_on_card"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  placeholder="Jon Smith"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="name_on_card-error"
                  {...register("name_on_card", { required: true })}
                />
              </div>
              {errors.name_on_card && (
                <p className={errorUnderInput} id="name_on_card-error">
                  Name on card is required.
                </p>
              )}
            </div>
            {/* card_number */}
            <div className="pb-4">
              <label htmlFor="card_number" className={inputLabel}>
                credit card number *
              </label>
              <Controller
                control={control}
                name="card_number"
                // rules={{ validate: () => meta?.erroredInputs?.card_number || ""}}
                render={({ field }) => (
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                      <svg {...getCardImageProps({ images })} height="35px" width="75px" />
                    </div>
                    <input
                      type="phone"
                      name="card_number"
                      id="card_number"
                      className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                      ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                      placeholder=""
                      defaultValue=""
                      aria-invalid="false"
                      aria-describedby="cnum-error"
                      {...register("card_number", { required: true })}
                      {...getCardNumberProps({ onChange: field.onChange, onBlur: field.onBlur })}
                      value={field.value}
                    />
                    <Error errors={errors} name="card_number" message="Card number is required." />
                  </div>
                )}
              />
              {meta.touchedInputs.card_number && meta.erroredInputs.card_number && (
                <p className={errorUnderInput} id="birthdate-error">
                  {meta.erroredInputs.card_number}
                </p>
              )}
            </div>
            <div className="flex flex-row">
              <div className="w-1/2 pb-4 pr-1">
                <label htmlFor="expiration" className={inputLabelNoWrap}>
                  expiration *
                </label>
                <Controller
                  control={control}
                  name="expiration"
                  render={({ field }) => (
                    <input
                      type="phone"
                      name="expiration"
                      id="expiration"
                      className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                      ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                      placeholder="MM/YY"
                      defaultValue=""
                      aria-invalid="false"
                      aria-describedby="expiration-error"
                      {...register("expiration", { required: true })}
                      {...getExpiryDateProps({ onChange: field.onChange, onBlur: field.onBlur })}
                      value={field.value}
                    />
                  )}
                />
                <Error errors={errors} name="expiration" message="Expiration date is required." />
                {meta.touchedInputs.expiration && meta.erroredInputs.expiration && (
                  <p className={errorUnderInput} id="expiration-error">
                    {meta.erroredInputs.expiration}
                  </p>
                )}
              </div>
              <div className="w-1/2 pb-4 pl-1">
                <label htmlFor="security_code" className={inputLabelNoWrap}>
                  cvv *
                </label>
                <Controller
                  control={control}
                  name="security_code"
                  render={({ field }) => (
                    <input
                      type="tel"
                      name="security_code"
                      id="security_code"
                      className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                      ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                      placeholder="helo"
                      defaultValue=""
                      aria-invalid="false"
                      aria-describedby="security_code-error"
                      {...register("security_code", { required: true })}
                      {...getCVCProps({ onChange: field.onChange, onBlur: field.onBlur })}
                      value={field.value}
                    />
                  )}
                />
                <Error errors={errors} name="security_code" message="CVV code is required." />
                {meta.touchedInputs.security_code && meta.erroredInputs.security_code && (
                  <p className={errorUnderInput} id="security_code-error">
                    {meta.erroredInputs.security_code}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button type="submit" className={primaryButton}>
                {isLoadingPayment ? loading : `submit $${paymentAmount} payment`}
              </button>
            </div>
          </div>
          <CouponModal
            open={open}
            setOpen={setOpen}
            applyCodeClick={handleCouponClick}
            continueToPayment={continueToPayment}
            isLoading={isLoading}
            isLoadingContinue={isLoadingContinue}
            couponErrorMessage={couponErrorMessage}
            setCouponErrorMessage={setCouponErrorMessage}
            successMessage={successMessage}
            paymentAmount={paymentAmount}
          />
        </form>
      </section>
    </>
  );
};
