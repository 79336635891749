import Select from "react-tailwindcss-select";
// import { useEffect } from "react";

export const SelectLocal = ({ id, options, currentValue, setCurrentValue }) => {
  const handleChange = (value) => {
    setCurrentValue(value);
  };

  return (
    <Select
      id={id}
      value={currentValue}
      onChange={handleChange}
      options={options}
      isClearable
      classNames={{
        menuButton: () =>
          "flex text-sm px-2 py-2 border border-black rounded shadow-sm transition-all duration-300 focus:outline-none" +
          " bg-white hover:border-gray-400 focus:border-pink-500 focus:ring focus:ring-pink-500/20 [&_p]:whitespace-break-spaces",
        listItem: () =>
          "block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded" +
          " break-normal text-gray-500 hover:bg-pink-100 hover:text-rasapink",
        tagItem: () => "flex text-sm bg-purple p-1 mr-1 border border-gray-200 rounded [&_svg]:w-4",
      }}
    />
  );
};
