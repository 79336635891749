import { useState } from "react";
import { Complete, Court, Drafting, PersonalInfo, Prosecutor } from "./icons";
import { ShowContent } from "./ShowContent";
import { useNavigate } from "react-router-dom";
import {
  draftingFiling,
  prosecutorReview,
  reductionComplete,
  waitingForCourt,
  yourPersonalInfo,
} from "./DescriptionsExpungement";
import { STATIC } from "./ProgressStepperExpungement";
import { actionEntity, content, LI, row } from "./ProcessExpungement";
import {
  getReductionCompleteDescription,
  getReductionDraftingDescription,
  getReductionPersonalInfoDescription,
  getReductionProsecutorReviewDescription,
  getReductionWaitingForCourtDescription,
} from "./DescriptionsReduction";

export const ProcessReduction = ({ psSurveyDone }) => {
  const navigate = useNavigate();
  const [isShowingPersonalInfo, setIsPersonalInfo] = useState(false);
  const [isShowingDraft, setIsShowingDraft] = useState(false);
  const [isShowingProsecutor, setIsShowingProsecutor] = useState(false);
  const [isShowingCourt, setIsShowingCourt] = useState(false);
  const [isShowingComplete, setIsShowingComplete] = useState(false);

  return (
    <>
      <ol className="mt-3">
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <PersonalInfo stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold">
                {yourPersonalInfo} <div className={actionEntity}> (you)</div>
              </div>
              <ShowContent isShowing={isShowingPersonalInfo} setIsShowing={setIsPersonalInfo} />
            </div>
          </div>
          {isShowingPersonalInfo && (
            <div className={content}>
              {getReductionPersonalInfoDescription(psSurveyDone, navigate)}
            </div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Drafting stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold">
                {draftingFiling} <div className={actionEntity}> (rasa)</div>
              </div>
              <ShowContent isShowing={isShowingDraft} setIsShowing={setIsShowingDraft} />
            </div>
          </div>
          {isShowingDraft && (
            <div className={content}>{getReductionDraftingDescription(false)}</div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Prosecutor stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold">
                {prosecutorReview} <div className={actionEntity}> (government)</div>
              </div>
              <ShowContent isShowing={isShowingProsecutor} setIsShowing={setIsShowingProsecutor} />
            </div>
          </div>
          {isShowingProsecutor && (
            <div className={content}>
              {getReductionProsecutorReviewDescription(false, false, null)}
            </div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Court stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold">
                {waitingForCourt} <div className={actionEntity}> (government)</div>
              </div>
              <ShowContent isShowing={isShowingCourt} setIsShowing={setIsShowingCourt} />
            </div>
          </div>
          {isShowingCourt && (
            <div className={content}>
              {getReductionWaitingForCourtDescription(false, false, false)}
            </div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Complete stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold">
                {reductionComplete} <div className={actionEntity}> (rasa)</div>
              </div>
              <ShowContent isShowing={isShowingComplete} setIsShowing={setIsShowingComplete} />
            </div>
          </div>
          {isShowingComplete && (
            <div className={content}>{getReductionCompleteDescription(false)}</div>
          )}
        </li>
      </ol>
    </>
  );
};
