import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { toTitleCase } from "../../../utils/toTitleCase";
import { useEffect, useState } from "react";
import { TextUs } from "./MessageBoxes";

const greyRow = "flex justify-between mb-4 p-2 bg-gray-100";
const valueDiv = "flex-1 w-44 font-semibold";
const options = { year: "numeric", month: "long", day: "numeric" };

const eliSentences = {
  marijEli: "that this case may be eligible for expungement",
  sealAndSetEliNow: "that this case may be eligible for sealing or set aside",
  sealEliNow: "that this case may be eligible for sealing",
  setEliNow: "that this case may be eligible for set aside",
};

export function CaseDetailsModalAZ({
  open,
  setOpen,
  setOpenMessageUs,
  data,
  receivedAnyMessage,
  hasFreshworks,
}) {
  let [caseStatus, setCaseStatus] = useState("");
  let [isClearableNow, setIsClearableNow] = useState(false);
  let [notEligibleNow, setNotEligibleNow] = useState("");
  let [reasons, setReasons] = useState("");
  // const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (data.expungementEligible) {
      setCaseStatus(eliSentences.marijEli);
      setIsClearableNow(true);
    } else if (data.sealingEligibleNow && data.setAsideEligibleNow) {
      setCaseStatus(eliSentences.sealAndSetEliNow);
      setIsClearableNow(true);
    } else if (data.sealingEligibleNow) {
      setCaseStatus(eliSentences.sealEliNow);
      setIsClearableNow(true);
    } else if (data.setAsideEligibleNow) {
      setCaseStatus(eliSentences.setEliNow);
      setIsClearableNow(true);
    } else if (data.sealingEligibleLater) {
      setCaseStatus(
        "that this case is not eligible for sealing now. However, it may become eligible for sealing after " +
          data.sealingEligibleDate,
      );
      setIsClearableNow(false);
      setNotEligibleNow(
        "Would you like to receive a reminder from Rasa when your waiting period is complete?",
      );
    } else if (data.setAsideEligibleLater) {
      setCaseStatus(
        "that this case is not eligible for set aside now. However, it may become eligible for set aside after " +
          data.setAsideEligibleDate,
      );
      setIsClearableNow(false);
      setNotEligibleNow(
        "Would you like to receive a reminder from Rasa when your waiting period is complete?",
      );
    } else if (data.needMoreInfo) {
      setCaseStatus("we need more information to determine eligibility for this case");
      setIsClearableNow(false);
      setNotEligibleNow(
        "If you would like one of our team members to manually determine your eligibility, ",
      );
    } else {
      //  if we get here, everything has been false; not eligible
      setCaseStatus("that this case is not eligible for clearance");
      setIsClearableNow(false);
      setNotEligibleNow("If you have a question about these results,");
      setReasons("");
    }
  }, [data]);

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-white py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[95vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <AlertDialog.Title className="mb-4 px-6 font-serif text-2xl">
              {data.violation}
            </AlertDialog.Title>
            <div className="-mt-2 ml-6 font-bold">Case Number: {data.caseNumber}</div>
            <div className="mb-6 ml-6 font-bold">Location: {data.court}</div>
            <AlertDialog.Description className="mb-8 px-6">
              Our preliminary screening indicates {caseStatus}.
            </AlertDialog.Description>
            {reasons && (
              <AlertDialog.Description className="mb-8 px-6">
                <b>Reason(s):</b> {reasons}.
              </AlertDialog.Description>
            )}
            {!receivedAnyMessage && (
              <>
                <AlertDialog.Description className="mb-8 px-6">
                  {isClearableNow ? (
                    <>
                      Rasa can help you clear your eligible records for <b>just $250 per case! </b>
                      If you would like to talk with us about next steps in your record clearing
                      process, please{" "}
                    </>
                  ) : (
                    <>{notEligibleNow} </>
                  )}
                  <Link onClick={() => setOpenMessageUs(true)}>
                    <span className="text-rasapink underline">click here to message us.</span>
                  </Link>
                </AlertDialog.Description>
              </>
            )}
            {hasFreshworks && <TextUs />}
            <div>
              <div className="px-6">
                {data.charges.map((charge, index) => {
                  let recordType = "--";
                  if (charge.disposition) {
                    if (charge.disposition.toLowerCase().includes("guilty")) {
                      recordType = "conviction";
                    } else {
                      recordType = toTitleCase(charge.disposition.toLowerCase());
                    }
                  }
                  return (
                    <div key={`charge-${data.case_number}-${index}`} className="mb-12">
                      <div className="mb-2 mt-6 font-bold">Charge {index + 1}</div>
                      <div className={greyRow}>
                        <div className="flex-1">Violation Description</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>
                          {charge.description
                            ? toTitleCase(charge.description.toLowerCase())
                            : "--"}
                        </div>
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">Severity</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>
                          {charge.severity ? toTitleCase(charge.severity.toLowerCase()) : "--"}
                        </div>
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">Judgment Date</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>
                          {charge.disposition_date
                            ? new Date(charge.disposition_date).toLocaleDateString("en-US", options)
                            : "--"}
                        </div>
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">Type of Record</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>{toTitleCase(recordType)}</div>
                      </div>
                      {/*<div className={greyRow}>*/}
                      {/*  <div>Location</div>*/}
                      {/*  <div>some data</div>*/}
                      {/*</div>*/}
                      <div className={greyRow}>
                        <div className="flex-1">Violation Code</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>{charge.code ? charge.code : "--"}</div>
                      </div>
                      {/*<div className={greyRow}>*/}
                      {/*  <div>Probation Agency</div>*/}
                      {/*  <div>some data</div>*/}
                      {/*</div>*/}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
// eligible in future
// Our preliminary screening indicates that this case is not eligible for expungement because you are in a waiting period. Under Utah law, individuals must wait a set period of time before they can expunge their criminal record. This waiting period is calculated from the LATEST of the following dates:
//
// if incarcerated, the date of release from incarceration;
// if ordered to complete probation, the date probation was completed or terminated unsuccessfully;
// if paroled, the date parole was completed or terminated unsuccessfully.
// This case is not eligible for clearance now, but there is a chance that it could become eligible for clearance in the future. The waiting period on this case is up on or around September 24, 2025.
//
// Would you like to receive an email from Rasa when your waiting period is complete?
//  yes, remind me!
//Hire rasa as my lawyer
// Have a question about this? Send us a message or schedule a consultation with us.
