import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const secureInstance = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 30000,
});

secureInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export async function updatePerson(params) {
  const { data } = await secureInstance.put("/v1/person/", params);
  return data;
}

export async function createMessage(profileId, params) {
  const { data } = await secureInstance.post("/v1/message/", params);
  return data;
}

export async function getMessages() {
  const { data } = await secureInstance.get("/v1/message/");
  return data;
}

export async function getPerson() {
  const { data } = await secureInstance.get("/v1/person/");
  return data;
}

export async function getCaseCount() {
  const { data } = await secureInstance.get("/v1/eligibility/count");
  return data;
}

export async function getEligibility() {
  const { data } = await secureInstance.get("/v1/eligibility/");
  return data;
}

export async function hasSeenResults() {
  const { data } = await secureInstance.put("/v1/eligibility/");
  return data;
}

export async function getPrice(params) {
  const { data } = await secureInstance.put("/v1/payment/price", params);
  return data;
}

export async function makePayment(params) {
  const { data } = await secureInstance.put("/v1/payment/", params);
  return data;
}

export async function verifyPerson(params) {
  const { data } = await secureInstance.put("/v1/person/verification", params);
  return data;
}

// personal statement info gathering
export async function saveAnswers(question, params) {
  const { data } = await secureInstance.put(`/v1/answer/${question}`, params);
  return data;
}

export async function getAnswersAll() {
  const { data } = await secureInstance.get("/v1/answer/");
  return data;
}

export async function getAnswersByQuestion(question) {
  const { data } = await secureInstance.get(`/v1/answer/${question}`);
  return data;
}

export async function forgotPassword(params) {
  let encoded = encodeURIComponent(params.username);
  const { data } = await secureInstance.post(`/v1/authentication/request?username=${encoded}`);
  return data;
}

export async function resetPassword(params) {
  const { data } = await secureInstance.post("/v1/authentication/reset", params);
  return data;
}

export async function getStatus() {
  const { data } = await secureInstance.get("/v1/status/");
  return data;
}

export async function updateStatus(params) {
  const { data } = await secureInstance.put("/v1/status/", params);
  return data;
}
