import {
  questionMain,
  question,
  inputWrapper,
  container2,
  textareaClass,
  errorClass,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { VOLUNTEER_WORK } from "./overview";
import { handleError } from "../../../utils/handleError";
import { handleSave } from "./handleSave";
import { RadioPS } from "./radioPS";
import { LOGIN } from "./lifeCircumstances";
import { Header } from "./header";

// allows for multiple
const WHERE_AND_TIME = "where_and_time";

export function VolunteerWork() {
  const navigate = useNavigate();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [VOLUNTEER_WORK]: "",
    },
  });
  const current = watch(VOLUNTEER_WORK);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(VOLUNTEER_WORK)
      .then((response) => {
        response.forEach((row) => {
          setValue(VOLUNTEER_WORK, row.selection);
          if (row.additional_json) {
            if (row.additional_json[WHERE_AND_TIME]) {
              setValue(WHERE_AND_TIME, row.additional_json[WHERE_AND_TIME]);
            }
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  const handlePreSave = (path) => {
    const values = getValues();
    if (values[VOLUNTEER_WORK]) {
      if (values[VOLUNTEER_WORK] === "yes" && values[WHERE_AND_TIME]) {
        // eslint-disable-next-line no-console
        console.log("values", values);
        setGenericErrorMessage("");
        const newDetails = [
          {
            selection: values[VOLUNTEER_WORK],
            additional_json: {
              [WHERE_AND_TIME]: values[WHERE_AND_TIME],
            },
          },
        ];
        handleSave(VOLUNTEER_WORK, newDetails, navigate, path);
      } else if (values[VOLUNTEER_WORK] === "no") {
        setGenericErrorMessage("");
        const newDetails = [
          {
            selection: values[VOLUNTEER_WORK],
          },
        ];
        handleSave(VOLUNTEER_WORK, newDetails, navigate, path);
      } else if (values[VOLUNTEER_WORK] === "yes" && !values[WHERE_AND_TIME]) {
        setGenericErrorMessage("Please fill in details.");
      }
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/education";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/children";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="menu">
        <div className={container2}>
          <h2 className={questionMain}>Are you involved in volunteer work?</h2>
          <div className="pb-4">
            <RadioPS
              name={VOLUNTEER_WORK}
              answer="yes"
              label="yes"
              register={register}
              {...register(VOLUNTEER_WORK)}
              current={current}
            />
            <RadioPS
              name={VOLUNTEER_WORK}
              answer="no"
              label="no"
              register={register}
              {...register(VOLUNTEER_WORK)}
              current={current}
            />
          </div>
          {current === "yes" && (
            <div>
              <h2 className={question}>
                Where do you volunteer and how long have you volunteered there? (You can list
                multiple places and lengths of time.)
              </h2>
              <div className={inputWrapper}>
                <textarea
                  placeholder="a local women's shelter for 8 months"
                  type="text"
                  name={WHERE_AND_TIME}
                  id={WHERE_AND_TIME}
                  className={textareaClass}
                  rows="4"
                  {...register(WHERE_AND_TIME)}
                ></textarea>
              </div>
            </div>
          )}
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext handleBackClick={handleBackClick} handleNextClick={handleNextClick} />
        </div>
      </section>
    </>
  );
}
