import { radio, radioLabel, radioWrapper } from "../constants/classnames";

export const Radio = ({ name, answer, label, register, handleMoreChange = () => {} }) => {
  const registeredName = register(name);

  const handleChange = (e) => {
    registeredName.onChange({
      target: {
        name: name,
        value: answer,
      },
    });
    handleMoreChange(e);
  };

  return (
    <div className={radioWrapper}>
      <input
        type="radio"
        name={`${name}Radio`}
        id={`${name}-${answer}`}
        className={radio}
        value={answer}
        aria-invalid="false"
        aria-describedby={`${name}-error`}
        onChange={handleChange}
      />
      <label htmlFor={`${name}-${answer}`} className={radioLabel}>
        {label}
      </label>
    </div>
  );
};
