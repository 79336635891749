import RasaLogo from "@images/rasa-logo.svg";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { updatePerson } from "../../../../api/secureApi";
import { handleError } from "../../../../utils/handleError";
import { STATE_LIST } from "../../../../constants/usStates";
import {
  errorUnderInput,
  inputLabel,
  inputWrapper,
  questionMain2,
} from "../../../../constants/classnames";

export function Address() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // eslint-disable-next-line no-console
  console.log("state in address---", state);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    const dataToSubmit = {
      profile_id: state.profile_id,
      ...data,
    };
    // eslint-disable-next-line no-console
    console.log("dataToSubmit in address.......", dataToSubmit);
    updatePerson(dataToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response in address", response);
        navigate("/register/ethnicity", { state: response });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in address", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto mb-16 mt-6 flex max-w-3xl flex-col px-4">
            <div className="flex max-w-3xl flex-col pb-8">
              {/*<h2 className="mb-2 font-serif text-2xl">What is your address?</h2>*/}
              <h2 className={questionMain2}>what is your address?</h2>
            </div>
            <div className="pb-4">
              <label htmlFor="address" className={inputLabel}>
                address *
              </label>
              <div className={inputWrapper}>
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="123 Main Street"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="address-error"
                  {...register("address", { required: true })}
                />
              </div>
              {errors.address && (
                <p className={errorUnderInput} id="address-error">
                  Answer is required.
                </p>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="address2" className={inputLabel}>
                apartment, suite, etc.
              </label>
              <div className={inputWrapper}>
                <input
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Apt A"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="address2-error"
                  {...register("address2")}
                />
              </div>
            </div>
            <div className="pb-4">
              <label htmlFor="city" className={inputLabel}>
                city *
              </label>
              <div className={inputWrapper}>
                <input
                  type="text"
                  name="city"
                  id="city"
                  placeholder="Sunnyville"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="city-error"
                  {...register("city", { required: true })}
                />
              </div>
              {errors.city && (
                <p className={errorUnderInput} id="address-error">
                  Answer is required.
                </p>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="state" className={inputLabel}>
                state *
              </label>
              <div className={inputWrapper}>
                <select
                  id="state"
                  name="state"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                            placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  {...register("state", { required: true })}
                >
                  <option value="" disabled />
                  {Object.entries(STATE_LIST).map(([k, v]) => (
                    <option key={k} value={k}>
                      {v}
                    </option>
                  ))}
                </select>
              </div>
              {errors.state && (
                <p className={errorUnderInput} id="address-error">
                  Answer is required.
                </p>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="zip" className={inputLabel}>
                zip code *
              </label>
              <div className={inputWrapper}>
                <input
                  type="text"
                  name="zip"
                  id="zip"
                  placeholder="12345"
                  className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="zip-error"
                  {...register("zip", { required: true })}
                />
              </div>
              {errors.zip && (
                <p className={errorUnderInput} id="address-error">
                  Answer is required.
                </p>
              )}
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 text-sm font-bold text-white hover:shadow-xl"
              >
                next
              </button>
              <Link to="/">
                <button
                  type="button"
                  className="w-full rounded-full bg-white px-24 py-4 text-sm font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-rasapink hover:shadow-xl"
                >
                  log out
                </button>
              </Link>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
