import RasaLogo from "@images/rasa-logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { labelMain, labelSecondary } from "../../../constants/classnames";
import { useEffect, useState } from "react";
import { STATE_LIST } from "../../../constants/usStates";
import { isFreeUtm } from "../../../constants/utmCodes";
import { checkReferral } from "../../../api/noAuthApi";

const recordClearanceOptions = {
  AZ: "Whether it might be eligible for record sealing, expungement, or set aside.",
  UT: "Whether it might be eligible for expungement.",
};

export function IntroInfo() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [usState, setUsState] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [hasPeerReferrer, setHasPeerReferrer] = useState(false);
  const [utm, setUtm] = useState(false);
  const [peerReferrerCode, setPeerReferrerCode] = useState(false);

  useEffect(() => {
    if (!state || !state.us_state) {
      navigate("/register");
    }
    let us_state = "";
    if (state?.us_state) {
      us_state = state.us_state;
      setUsState(us_state);
    }
    if (state?.utm_code) {
      const utm_code = state.utm_code;
      setUtm(utm_code);
      if (isFreeUtm(utm_code)) {
        setIsFree(true);
      } else {
        setIsFree(false);
      }
    }
    if (state?.peer_referrer_code) {
      const code = state.peer_referrer_code;
      setPeerReferrerCode(code);
      checkReferral({ code: code })
        .then(() => setHasPeerReferrer(true))
        .catch(() => setHasPeerReferrer(false));
    }
  }, [state, navigate]);

  const handleRegisterClick = () => {
    const newState = {
      us_state: usState,
      utm_code: utm,
      peer_referrer_code: peerReferrerCode,
    };
    navigate("/register/basic-info", { state: newState });
  };

  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={labelMain}>check your {STATE_LIST[usState]} eligibility</h2>
          {hasPeerReferrer && (
            <div>
              <div className="mb-6 rounded-md bg-pink-100 px-6 py-4 text-center">
                <div>This screening usually costs $15,</div>
                <div>
                  but <b>you are getting $5 off</b> because you used your friend's referral link!
                </div>
              </div>
            </div>
          )}
          <h3 className={labelSecondary}>
            {isFree || hasPeerReferrer
              ? "Create an account to find out:"
              : "For $15, you will find out:"}
          </h3>
        </div>
      </section>
      <section id="bodyText">
        <div className="container mx-auto mt-2 flex max-w-3xl flex-col px-4">
          <div className="mb-6 overflow-hidden rounded-md bg-pink-100 px-6 py-4">
            <ul className="mx-3 list-disc">
              <li>
                What is on your <b>{STATE_LIST[usState]}</b> criminal record.
              </li>
              <li>{recordClearanceOptions[usState]}</li>
            </ul>
          </div>
          <div className="mb-4 text-center font-bold leading-4 text-gray-600">
            Please note: This is a <i>preliminary</i> screening and is not legal advice.
          </div>
          <div className="leading-6">
            To protect your privacy, you will be asked to verify your identity before seeing your
            record.
          </div>
        </div>
      </section>
      <section id="button">
        <div className="container mx-auto mb-16 mt-10 flex max-w-3xl flex-col justify-center px-4">
          <button
            onClick={handleRegisterClick}
            type="button"
            className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
          >
            let's go!
          </button>
          <Link to="/login">
            <button
              type="button"
              className="w-full rounded-full bg-white px-24 py-4 font-bold text-gray-900 shadow-sm ring-2 ring-inset ring-rasapink hover:shadow-xl"
            >
              login
            </button>
          </Link>
        </div>
      </section>
    </>
  );
}
