import { Complete, Court, Drafting, Prosecutor } from "./icons";
import {
  draftingFiling,
  prosecutorReview,
  motionComplete,
  waitingForCourt,
} from "./DescriptionsExpungement";
import { useEffect, useState } from "react";
import { DEAD_MATTERS, DONE_FAILED, li, span } from "./ProgressStepperExpungement";

export const DONE = "done";
export const CURRENT = "current";
export const FUTURE = "future";

const getStepStatus = (currentStep, displayStep) => {
  let currentIndex = stepsOther.indexOf(currentStep);
  const displayIndex = stepsOther.indexOf(displayStep);
  if (displayIndex < currentIndex) {
    return DONE;
  } else if (displayIndex > currentIndex) {
    return FUTURE;
  }
  return CURRENT;
};

export const stepsOther = [
  draftingFiling, // 0
  prosecutorReview, // 1
  waitingForCourt, // 2
  motionComplete, // 3
];

export const ProgressStepperOther = ({ currentStep }) => {
  const [stepsStatues, setStepsStatues] = useState([FUTURE, FUTURE, FUTURE, FUTURE]);

  useEffect(() => {
    const statuses = [];
    for (let i = 0; i < stepsOther.length; i++) {
      if (DEAD_MATTERS.includes(currentStep)) {
        statuses[i] = DONE_FAILED;
      } else {
        statuses[i] = getStepStatus(currentStep, stepsOther[i]);
      }
    }
    setStepsStatues(statuses);
  }, [currentStep]);

  return (
    <ol className="flex w-full items-center">
      <li className={li(stepsStatues[0])}>
        <span className={span(stepsStatues[0])}>
          {/*document*/}
          <Drafting stepStatus={stepsStatues[0]} />
        </span>
      </li>
      <li className={li(stepsStatues[1])}>
        <span className={span(stepsStatues[1])}>
          {/*scale*/}
          <Prosecutor stepStatus={stepsStatues[1]} />
        </span>
      </li>
      <li className={li(stepsStatues[2])}>
        <span className={span(stepsStatues[2])}>
          {/*library building*/}
          <Court stepStatus={stepsStatues[2]} />
        </span>
      </li>
      <li className="flex items-center">
        <span className={span(stepsStatues[3])}>
          <Complete stepStatus={stepsStatues[3]} />
        </span>
      </li>
    </ol>
  );
};
