import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { primaryButtonSmall } from "../../../constants/classnames";
import { loading } from "../../../components/loading";
import { formatDashesString, formatLongString, isDateValid } from "./utils";
import { format } from "date-fns";
import { updateStatus } from "../../../api/secureApi";

const formatString = "mm/dd/yyyy";

export function BciApplicationDateModal({ open, setOpen, isCurrent, setBciSubmitted }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [submittedDate, setSubmittedDate] = useState("");
  const [today] = useState(format(new Date(), formatLongString));
  const [error, setError] = useState("");

  const handleClick = () => {
    setIsLoading(true);
    const formatted = format(submittedDate, formatDashesString);
    const state_specific_data = {
      bci_submitted: formatted,
    };
    updateStatus({ state_specific_data })
      .then(() => {
        // this is the saved date displayed in all tabs
        setBciSubmitted(submittedDate);
        setError("");
        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.log("error saving bci submitted date:", error);
        setError(
          "We're sorry, there was an error saving your BCI Application Submitted Date. Please contact us so we can fix this problem.",
        );
      });
  };

  const validate = (date) => {
    if (!date) {
      setError("");
      setIsDisabled(true);
    } else if (isDateValid(date)) {
      setError("");
      setIsDisabled(false);
    } else {
      setError(`please use ${formatString} format`);
      setIsDisabled(true);
    }
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-fit flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <AlertDialog.Description className="mb-8 px-6">
              <div id="form">
                <form>
                  <div className="container mx-auto mb-16 mt-10 flex max-w-3xl flex-col px-4">
                    <div>
                      {isCurrent && (
                        <div>
                          We are currently waiting for YOU to submit your application to BCI. If you
                          have already submitted your application, please let us know what date you
                          submitted it, so we can record it here and track your application.
                        </div>
                      )}
                      <div className="my-4">Today's date is {today}.</div>
                      <div className="mt-2">BCI Application submitted date:</div>
                      <div className="relative rounded-md">
                        <input
                          type="text"
                          // name="submittedDate"
                          // id="submittedDate"
                          className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                          placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                          placeholder={formatString}
                          value={submittedDate}
                          onChange={(e) => setSubmittedDate(e.target.value)}
                          onBlur={(e) => validate(e.target.value)}
                        />
                        {error && (
                          <div className="mb-2  text-center text-sm text-red-600">{error}</div>
                        )}
                      </div>
                      <div className="mt-4 text-center">
                        <button
                          type="button"
                          className={primaryButtonSmall}
                          onClick={handleClick}
                          disabled={isDisabled}
                        >
                          {isLoading ? loading : "save date"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </AlertDialog.Description>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
