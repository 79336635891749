import { Link } from "react-router-dom";
import { ContactInfo } from "../results/ContactInfo";
const li = "mt-4";

export const Checklist = ({
  showBciDateNeeded,
  showPsNeeded,
  paymentNeeded,
  setOpenApplicationSubmitted,
  navigate,
}) => {
  return (
    <div>
      {(showBciDateNeeded || showPsNeeded || paymentNeeded) && (
        <div>
          In order for us to proceed with your record clearance, we need you to do the following:
        </div>
      )}
      <ul className="mx-3 list-disc">
        {paymentNeeded && (
          <li className={li}>
            <span className="font-bold">payment needed: </span>
            It looks like we may need a payment from you to move forward. Please contact us so we
            can get your cases going!
            <ContactInfo />
          </li>
        )}
        {showBciDateNeeded && (
          <li className={li}>
            <span className="font-bold">submit BCI application: </span>
            <button
              type="button"
              className="text-rasapink underline"
              onClick={() => setOpenApplicationSubmitted(true)}
            >
              let us know
            </button>{" "}
            the date that you gave your BCI application to bci so we can track it
          </li>
        )}
        {showPsNeeded && (
          <li className={li}>
            <span className="font-bold">personal statement questions: </span>
            Please fill out your{" "}
            <Link onClick={() => navigate("/statement")}>
              <span className="text-rasapink underline">personal statement questions</span>
            </Link>{" "}
            so we can use this information in your court documents to tell the court how your
            criminal record has been a barrier and what has positive changes you have made in your
            life since your time in the justice system
          </li>
        )}
        {!showBciDateNeeded && !showPsNeeded && !paymentNeeded && (
          <div className="my-6">You are all caught up!</div>
        )}
      </ul>
    </div>
  );
};
