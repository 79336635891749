import {
  questionMain,
  inputWrapper,
  question,
  textareaClass,
  textareaClassPink,
  inputPink,
  buttonFlexContainer,
  animate,
  pinkSection,
  container2,
  errorClass,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Checkbox } from "../../../components/checkbox";
import { ButtonNext } from "../../../components/buttonNext";
import { handleSave, setInitialValues } from "./handleSave";
import { CIRCUMSTANCES } from "./overview";
import { Header } from "./header";

// selections
const SOCIAL_PRESSURE = "socialized with the 'wrong crowd'";
const CHILDHOOD_TRAUMA = "childhood trauma";
const SUBSTANCE_USE = "substance use (alcohol/ drugs)";
const DEATH_OF_LOVED_ONE = "death of a loved one";
const FREE_ANSWER = "free answer";

// additional details
const PROGRAM = "substance_program";
const SOBRIETY = "sobriety_time";
const LOVED_ONE = "loved_one";
const TRAUMA_DETAIL = "trauma_detail";
const DETAIL = "detail";

// path
export const LOGIN = "/login";

export function LifeCircumstances() {
  const navigate = useNavigate();
  const [currentValues, setCurrentValues] = useState([]);
  const [details, setDetails] = useState([]);
  const [showSubstanceDetailInput, setShowSubstanceDetailInput] = useState(false);
  const [showLossDetailInput, setShowLossDetailInput] = useState(false);
  const [showDetailInput, setShowDetailInput] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  useEffect(() => {
    setInitialValues(CIRCUMSTANCES, setCurrentValues, setDetails, navigate, setGenericErrorMessage);
  }, [navigate]);

  useEffect(() => {
    // if selection has additional questions, show them
    if (currentValues.includes(DEATH_OF_LOVED_ONE)) {
      setShowLossDetailInput(true);
    } else {
      setShowLossDetailInput(false);
    }
    if (currentValues.includes(SUBSTANCE_USE)) {
      setShowSubstanceDetailInput(true);
    } else {
      setShowSubstanceDetailInput(false);
    }
    if (currentValues.includes(CHILDHOOD_TRAUMA)) {
      setShowDetailInput(true);
    } else {
      setShowDetailInput(false);
    }
  }, [currentValues]);

  const handleDetailsChange = (e, selectionName, detailName) => {
    const newDetail = e.target.value;
    const currentDetails = [...details];
    const currentSelection = currentDetails.find((item) => item.selection === selectionName);
    if (currentSelection) {
      currentSelection.additional_json[detailName] = newDetail;
    } else {
      currentDetails.push({
        selection: selectionName,
        additional_json: { [detailName]: newDetail },
      });
    }
    // eslint-disable-next-line no-console
    console.log("currentDetails", currentDetails);
    setDetails(currentDetails);
  };

  const handlePreSave = (path) => {
    if (currentValues.length > 0 || details.length > 0) {
      //   loop through details, if doesn't exist in values then delete it in details
      //   if it's other, delete only if empty?
      const currentDetails = [...details];
      const newDetails = currentDetails.filter(
        (obj) => currentValues.includes(obj.selection) || obj.selection === FREE_ANSWER,
      );
      // if we saved FREE_ANSWER as a selection but then the value got set back to "",
      // don't save that
      const freeAnswerIndex = newDetails.findIndex(
        (item) => item.selection === FREE_ANSWER && item.additional_json[DETAIL] === "",
      );
      if (freeAnswerIndex > -1) {
        newDetails.splice(freeAnswerIndex, 1);
      }
      setDetails(newDetails);
      // eslint-disable-next-line no-console
      console.log("details to save", newDetails);
      handleSave(CIRCUMSTANCES, newDetails, navigate, path);
    } else {
      navigate(path);
    }
  };

  const handleNextClick = () => {
    const path = "/statement/probation";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="form">
        <div className={container2}>
          <h2 className={questionMain}>
            What circumstances in your life led to your involvement with the justice system? (Please
            select all that apply.)
          </h2>
          <div>
            <Checkbox
              value={SOCIAL_PRESSURE}
              currentValues={currentValues}
              setCurrentValues={setCurrentValues}
            />
            <div className={showLossDetailInput ? pinkSection : ""}>
              <Checkbox
                value={DEATH_OF_LOVED_ONE}
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              {showLossDetailInput && (
                <div className={animate}>
                  <div className={question}>Who was your loved one?</div>
                  <div className={inputWrapper}>
                    <input
                      placeholder="father, sister, close friend"
                      type="text"
                      name={LOVED_ONE}
                      id={LOVED_ONE}
                      className={inputPink}
                      onChange={(e) => handleDetailsChange(e, DEATH_OF_LOVED_ONE, LOVED_ONE)}
                      defaultValue={
                        details.find(
                          (item) => item.additional_json && item.additional_json[LOVED_ONE],
                        )?.additional_json[LOVED_ONE] || ""
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={showSubstanceDetailInput ? pinkSection : ""}>
              <Checkbox
                value={SUBSTANCE_USE}
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              {showSubstanceDetailInput && (
                <div className={animate}>
                  <div className={question}>
                    If you completed a treatment program, please give the name of the program:
                  </div>
                  <div className={inputWrapper}>
                    <input
                      type="text"
                      name={PROGRAM}
                      id={PROGRAM}
                      className={inputPink}
                      onChange={(e) => handleDetailsChange(e, SUBSTANCE_USE, PROGRAM)}
                      defaultValue={
                        details.find(
                          (item) => item.additional_json && item.additional_json[PROGRAM],
                        )?.additional_json[PROGRAM] || ""
                      }
                    />
                  </div>
                  <div className={question}>
                    If you have maintained sobriety, please tell us for how long:
                  </div>
                  <div className={inputWrapper}>
                    <input
                      placeholder="3 years"
                      type="text"
                      name={SOBRIETY}
                      id={SOBRIETY}
                      className={inputPink}
                      onChange={(e) => handleDetailsChange(e, SUBSTANCE_USE, SOBRIETY)}
                      defaultValue={
                        details.find(
                          (item) => item.additional_json && item.additional_json[SOBRIETY],
                        )?.additional_json[SOBRIETY] || ""
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className={showDetailInput ? pinkSection : ""}>
              <Checkbox
                value={CHILDHOOD_TRAUMA}
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              {showDetailInput && (
                <div className={animate}>
                  <div className={question}>Please give brief details</div>
                  <div className={inputWrapper}>
                    <textarea
                      placeholder="abuse, neglect, in foster care system, parents' divorce, parent incarcerated"
                      type="text"
                      name={TRAUMA_DETAIL}
                      id={TRAUMA_DETAIL}
                      className={textareaClassPink}
                      rows="4"
                      onChange={(e) => handleDetailsChange(e, CHILDHOOD_TRAUMA, TRAUMA_DETAIL)}
                      defaultValue={
                        details.find(
                          (item) => item.additional_json && item.additional_json[TRAUMA_DETAIL],
                        )?.additional_json[TRAUMA_DETAIL] || ""
                      }
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
            <div className={question}>Please add other or additional circumstances:</div>
            <div className={inputWrapper}>
              <textarea
                type="text"
                name={FREE_ANSWER}
                id={FREE_ANSWER}
                className={textareaClass}
                rows="6"
                onChange={(e) => handleDetailsChange(e, FREE_ANSWER, DETAIL)}
                defaultValue={
                  details.find(
                    (item) =>
                      item.selection === FREE_ANSWER &&
                      item.additional_json &&
                      item.additional_json[DETAIL],
                  )?.additional_json[DETAIL] || ""
                }
              ></textarea>
            </div>
          </div>
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <div className={buttonFlexContainer}>
            <div></div>
            <ButtonNext handleClick={handleNextClick} />
          </div>
        </div>
      </section>
    </>
  );
}
