import RasaLogo from "@images/rasa-logo.svg";
import * as AlertDialog from "@radix-ui/react-alert-dialog";

export function WarningModal({
  openWarning,
  setOpenWarning,
  openCasesBool,
  probationOrParoleBool,
}) {
  return (
    <AlertDialog.Root open={openWarning}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-800 opacity-90" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[110vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-white py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex max-h-[80vh] flex-col">
            <div className="flex justify-center">
              <RasaLogo className="h-9" />
            </div>
            <AlertDialog.Title className="my-4 px-6 text-center font-serif text-2xl">
              You are likely not eligible right now
            </AlertDialog.Title>
            <AlertDialog.Description className="mb-8 px-6 text-xl">
              <div className="my-4 mt-1">
                {openCasesBool && (
                  <p className="my-6">
                    You answered that you <b>currently have open cases</b>. All your cases must be
                    closed to be eligible for record clearance.
                  </p>
                )}
                {probationOrParoleBool && (
                  <p className="my-6">
                    You answered that you are <b>currently on probation or parole</b>. Probation or
                    parole must be completed to be eligible for record clearance.
                  </p>
                )}
              </div>
            </AlertDialog.Description>
            <button
              type="button"
              className="m-6 rounded-full bg-rasapink p-4 font-bold text-white hover:shadow-xl"
              onClick={() => setOpenWarning(false)}
            >
              <div>I understand I'm not currently eligible.</div>
              <div>continue anyway</div>
            </button>
            <div className="h-4"> .</div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
