import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { container4 } from "../../../constants/classnames";
import { Cases } from "./Cases";
import { BciApplicationDateModal } from "./bciApplicationDateModal";
import { ProcessExplanation } from "./ProcessExplanation";
import { Checklist } from "./Checklist";
import { getAnswersAll, getStatus } from "../../../api/secureApi";
import { EXPUNGEMENT, REDUCTION } from "./constants";
import {
  bciAppPhase,
  formatShortString,
  mapExpungementWorkflowToStatus,
  mapOtherMotionWorkflowToStatus,
  mapReductionWorkflowToStatus,
  needPS,
} from "./utils";
import { format, parseISO } from "date-fns";

const compareCaseNumber = (a, b) => a.case_year - b.case_year || a.case_number - b.case_number;

const tabStart =
  "rounded-t-lg border-t border-x border-pink-100 bg-rasapink p-4 leading-4 text-gray-100 tracking-tighter focus:outline-none";
const activeTabStart =
  "rounded-t-lg border border-white bg-white p-4 leading-4 text-black tracking-tighter font-semibold focus:outline-none";
const tab = "-ml-1 " + tabStart;
const activeTab = "-ml-1 " + activeTabStart;
const yourChecklist = "your checklist";
const yourCases = "your cases";
const theProcess = "the process";

export function MatterStatus() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [openApplicationSubmitted, setOpenApplicationSubmitted] = useState(false);
  const [bciSubmitted, setBciSubmitted] = useState(null);
  const [showBciDateNeeded, setShowBciDateNeeded] = useState(true);
  const [oneExpungementInBciApplicationStage, setOneExpungementInBciApplicationStage] =
    useState(false);
  const [psSurveyDone, setPsSurveyDone] = useState(false);
  const [showPsNeeded, setShowPsNeeded] = useState(true);
  const [oneExpungementInPersonalStatementStage, setOneExpungementInPersonalStatementStage] =
    useState(false);
  const [paymentNeeded, setPaymentNeeded] = useState(false);
  const [mattersExpungement, setMattersExpungement] = useState([]);
  const [mattersReduction, setMattersReduction] = useState([]);
  const [mattersOther, setMattersOther] = useState([]);
  const [selectedTab, setSelectedTab] = useState(yourCases);

  useEffect(() => {
    if (!psSurveyDone) {
      // only reason to call this is to set psSurveyDone to true
      getAnswersAll()
        .then((response) => {
          const psAnswersCount = response.length;
          if (psAnswersCount > 0) {
            setPsSurveyDone(true);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error getting all answers", error);
        });
    }
  }, [psSurveyDone]);

  useEffect(() => {
    setShowPsNeeded(
      !psSurveyDone &&
        (mattersExpungement.length > 0 || mattersReduction.length > 0) &&
        (oneExpungementInBciApplicationStage || oneExpungementInPersonalStatementStage),
    );
  }, [
    psSurveyDone,
    mattersExpungement,
    mattersReduction,
    oneExpungementInBciApplicationStage,
    oneExpungementInPersonalStatementStage,
  ]);

  useEffect(() => {
    getStatus()
      .then(async (response) => {
        // eslint-disable-next-line no-console
        console.log("response", response);
        const state_specific_data = response?.state_specific_data;
        const personal_statement_drafted = response?.personal_statement_drafted;
        if (personal_statement_drafted) {
          setPsSurveyDone(true);
        }
        let bciDate;
        if ("bci_submitted" in state_specific_data && state_specific_data.bci_submitted) {
          bciDate = format(parseISO(state_specific_data.bci_submitted), formatShortString);
          setBciSubmitted(bciDate);
        }
        const matters = response?.matters;
        const expMatters = [];
        const reduMatters = [];
        const otherMatters = [];
        matters.forEach((m) => {
          if (m.legal_action_type === EXPUNGEMENT) {
            expMatters.push(
              mapExpungementWorkflowToStatus(m, setPaymentNeeded, showPsNeeded, showBciDateNeeded),
            );
            if (m?.workflow_stage?.toLowerCase().includes(bciAppPhase)) {
              setOneExpungementInBciApplicationStage(true);
            } else if (m?.workflow_stage?.toLowerCase().includes(needPS)) {
              setOneExpungementInPersonalStatementStage(true);
            }
          } else if (m.legal_action_type === REDUCTION) {
            reduMatters.push(mapReductionWorkflowToStatus(m, setPaymentNeeded, showPsNeeded));
            if (
              m?.workflow_stage?.toLowerCase().includes("need info") &&
              !m?.workflow_stage?.toLowerCase().includes("pmt")
            ) {
              setOneExpungementInPersonalStatementStage(true);
            }
          } else {
            otherMatters.push(mapOtherMotionWorkflowToStatus(m));
          }
        });
        setMattersExpungement(expMatters.sort(compareCaseNumber));
        setMattersReduction(reduMatters.sort(compareCaseNumber));
        setMattersOther(otherMatters.sort(compareCaseNumber));
        setShowBciDateNeeded(
          !bciDate && expMatters.length > 0 && oneExpungementInBciApplicationStage,
        );
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsLoading(false);
        if (error?.response?.status === 401) {
          navigate("/login");
        }
      });
  }, [
    navigate,
    oneExpungementInBciApplicationStage,
    setOneExpungementInBciApplicationStage,
    oneExpungementInPersonalStatementStage,
    setOneExpungementInPersonalStatementStage,
    showBciDateNeeded,
    showPsNeeded,
    setMattersExpungement,
    setMattersReduction,
    setMattersOther,
  ]);

  return (
    <div className={container4}>
      <div className="mx-auto w-full max-w-xl rounded border border-rasapink">
        <div className="rounded-t bg-rasapink pt-4">
          <div className="flex">
            <button
              className={selectedTab === yourChecklist ? activeTabStart : tabStart}
              onClick={() => setSelectedTab(yourChecklist)}
            >
              {yourChecklist}
            </button>
            <button
              className={selectedTab === yourCases ? activeTab : tab}
              onClick={() => setSelectedTab(yourCases)}
            >
              {yourCases}
            </button>
            <button
              className={selectedTab === theProcess ? activeTab : tab}
              onClick={() => setSelectedTab(theProcess)}
            >
              {theProcess}
            </button>
          </div>
        </div>
        {selectedTab === yourChecklist && (
          <div id={yourChecklist} className="rounded-lg bg-white p-4 shadow-md">
            <Checklist
              showBciDateNeeded={showBciDateNeeded}
              showPsNeeded={showPsNeeded}
              paymentNeeded={paymentNeeded}
              setOpenApplicationSubmitted={setOpenApplicationSubmitted}
              navigate={navigate}
              mattersExpungement={mattersExpungement}
            />
          </div>
        )}
        {selectedTab === yourCases && (
          <div id={yourCases} className="rounded-lg bg-white p-3 shadow-md">
            <div>
              We want to make sure you know where your cases are in the process at all times. The
              timeline below will show you where each of your cases is at this time.
            </div>
            <Cases
              navigate={navigate}
              isLoading={isLoading}
              bciSubmitted={bciSubmitted}
              psSurveyDone={psSurveyDone}
              mattersExpungement={mattersExpungement}
              mattersReduction={mattersReduction}
              mattersOther={mattersOther}
              setBciSubmitted={setBciSubmitted}
            />
          </div>
        )}
        {selectedTab === theProcess && (
          <div id={theProcess} className="rounded-lg bg-white p-4 shadow-md">
            <div className="mb-4">Click the down arrows next to each line to learn more.</div>
            <ProcessExplanation
              setOpenApplicationSubmitted={setOpenApplicationSubmitted}
              bciSubmitted={bciSubmitted}
              showBciDateNeeded={showBciDateNeeded}
              psSurveyDone={psSurveyDone}
              isPersonalStatementNeeded={showPsNeeded}
              mattersReduction={mattersReduction}
              mattersOther={mattersOther}
              mattersExpungement={mattersExpungement}
            />
          </div>
        )}
      </div>
      <BciApplicationDateModal
        open={openApplicationSubmitted}
        setOpen={setOpenApplicationSubmitted}
        isCurrent={false}
        setBciSubmitted={setBciSubmitted}
      />
    </div>
  );
}
