import { getAnswersByQuestion, saveAnswers } from "../../../api/secureApi";
import { handleError } from "../../../utils/handleError";
import { LOGIN } from "./lifeCircumstances";
import { cleanLocalStorage } from "../menu";

export const setInitialValues = (
  question,
  setCurrentValues,
  setDetails,
  navigate,
  setGenericErrorMessage,
) => {
  getAnswersByQuestion(question)
    .then((response) => {
      // eslint-disable-next-line no-console
      console.log("response in get by question", response);
      const newValues = [];
      const newDetails = [];
      response.forEach((row) => {
        newValues.push(row.selection);
        newDetails.push({ selection: row.selection, additional_json: row.additional_json });
      });
      setCurrentValues(newValues);
      setDetails(newDetails);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log("error getting answers", error);
      handleError(error, setGenericErrorMessage, navigate);
    });
};

export const handleDetailsChange = (e, selectionName, detailName, details, setDetails) => {
  const newDetail = e.target.value;
  const currentDetails = [...details];
  const currentSelection = currentDetails.find((item) => item.selection === selectionName);
  if (currentSelection) {
    currentSelection.additional_json[detailName] = newDetail;
  } else {
    currentDetails.push({
      selection: selectionName,
      additional_json: { [detailName]: newDetail },
    });
  }
  // eslint-disable-next-line no-console
  console.log("currentDetails", currentDetails);
  setDetails(currentDetails);
};

export const handleSave = (question, params, navigate, path) => {
  if (path === LOGIN) {
    handleSaveThenLogout(question, params, navigate, path);
  } else {
    saveAnswers(question, params)
      .then(() => {
        navigate(path);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in saving", error);
      });
  }
};

export const handleSaveThenLogout = (question, params, navigate, path) => {
  saveAnswers(question, params)
    .then(() => {
      cleanLocalStorage();
      navigate(path);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log("error in saving", error);
    });
};
