import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { logo } from "../constants/classnames";
import RasaLogo from "@images/rasa-logo.svg";
import { cleanLocalStorage } from "../app/routes/menu";

export function MenuLayout() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleLogoutClick = () => {
    cleanLocalStorage();
    navigate("/login");
  };

  return (
    <>
      <div className="flex justify-between">
        <div>
          <Link onClick={() => navigate("/menu", { state: state })}>
            <div className="mt-4 px-6 text-base tracking-tighter text-rasapink">menu</div>
          </Link>
        </div>
        <div>
          <Link onClick={handleLogoutClick}>
            <div className="mt-4 px-6 text-base tracking-tighter text-rasapink">log out</div>
          </Link>
        </div>
      </div>
      <div className={logo}>
        <RasaLogo className="h-7" />
      </div>
      <Outlet />
    </>
  );
}
