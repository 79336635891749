import {
  container2,
  question,
  inputWrapper,
  input,
  questionMain,
  textareaClass,
  errorClass,
} from "../../../constants/classnames";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { getAnswersByQuestion } from "../../../api/secureApi";
import { ButtonBackNext } from "../../../components/buttonBackNext";
import { GRANDCHILDREN } from "./overview";
import { handleError } from "../../../utils/handleError";
import { handleSave } from "./handleSave";
import { RadioPS } from "./radioPS";
import { Header } from "./header";
import { LOGIN } from "./lifeCircumstances";

const HOW_MANY = "how_many";
const AGES = "ages";

export function Grandchildren() {
  const navigate = useNavigate();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { getValues, register, setValue, watch } = useForm({
    mode: "all",
    defaultValues: {
      [GRANDCHILDREN]: "",
    },
  });
  const current = watch(GRANDCHILDREN);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAnswersByQuestion(GRANDCHILDREN)
      .then((response) => {
        response.forEach((row) => {
          setValue(GRANDCHILDREN, row.selection);
          if (row.additional_json) {
            if (row.additional_json[HOW_MANY]) {
              setValue(HOW_MANY, row.additional_json[HOW_MANY]);
            }
            if (row.additional_json[AGES]) {
              setValue(AGES, row.additional_json[AGES]);
            }
          }
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error getting answers", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  }, [setValue, navigate]);

  const handlePreSave = (path) => {
    const values = getValues();
    if (values[GRANDCHILDREN]) {
      if (values[GRANDCHILDREN] === "yes" && values[HOW_MANY] && values[AGES]) {
        // eslint-disable-next-line no-console
        console.log("values", values);
        setGenericErrorMessage("");
        const newDetails = [
          {
            selection: values[GRANDCHILDREN],
            additional_json: {
              [HOW_MANY]: values[HOW_MANY],
              [AGES]: values[AGES],
            },
          },
        ];
        handleSave(GRANDCHILDREN, newDetails, navigate, path);
      } else if (values[GRANDCHILDREN] === "no") {
        setGenericErrorMessage("");
        const newDetails = [
          {
            selection: values[GRANDCHILDREN],
          },
        ];
        handleSave(GRANDCHILDREN, newDetails, navigate, path);
      } else if (values[GRANDCHILDREN] === "yes" && (!values[HOW_MANY] || !values[AGES])) {
        setGenericErrorMessage("Please fill in details.");
      }
    } else {
      navigate(path);
    }
  };

  const handleBackClick = () => {
    const path = "/statement/children";
    handlePreSave(path);
  };

  const handleNextClick = () => {
    const path = "/statement/housing";
    handlePreSave(path);
  };

  const handleOverviewClick = () => {
    const path = "/statement-overview";
    handlePreSave(path);
  };

  const handleLogoutClick = () => {
    const path = LOGIN;
    handlePreSave(path);
  };

  return (
    <>
      <Header handleOverviewClick={handleOverviewClick} handleLogoutClick={handleLogoutClick} />
      <section id="heading">
        <div className={container2}>
          <div className={questionMain}>Do you have grandchildren?</div>
          <div className="pb-4">
            <RadioPS
              name={GRANDCHILDREN}
              answer="yes"
              label="yes"
              register={register}
              {...register(GRANDCHILDREN)}
              current={current}
            />
            <RadioPS
              name={GRANDCHILDREN}
              answer="no"
              label="no"
              register={register}
              {...register(GRANDCHILDREN)}
              current={current}
            />
          </div>
          {current === "yes" && (
            <div>
              <h2 className={question}>How many grandchildren do you have?</h2>
              <div className={inputWrapper}>
                <input
                  placeholder="3"
                  type="text"
                  name={HOW_MANY}
                  id={HOW_MANY}
                  className={input}
                  {...register(HOW_MANY)}
                />
              </div>
              <h2 className={question}>What are the age(s) of your grandchild(ren)?</h2>
              <div className={inputWrapper}>
                <textarea
                  placeholder="6 years, 8 years, 12 years"
                  type="text"
                  name={AGES}
                  id={AGES}
                  className={textareaClass}
                  rows="2"
                  {...register(AGES)}
                ></textarea>
              </div>
            </div>
          )}
          {genericErrorMessage && (
            <div className={errorClass} id="error">
              {genericErrorMessage}
            </div>
          )}
          <ButtonBackNext handleBackClick={handleBackClick} handleNextClick={handleNextClick} />
        </div>
      </section>
    </>
  );
}
