import { useLocation, useNavigate } from "react-router-dom";
import { STATE_LIST } from "../../../constants/usStates";
import { PriceBox } from "../results/PriceBox";
import { useEffect, useState } from "react";
import { MANDATORY_SURVEY } from "../../../constants/surveyTypes";

export function CourtData() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // eslint-disable-next-line no-console
  console.log("state in court data page", state);
  const [isFeeWaiverEligible, setIsFeeWaiverEligible] = useState(false);

  useEffect(() => {
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    setIsFeeWaiverEligible(currentMandatorySurvey?.answers?.is_fee_waiver_eligible);
  }, [state.surveys]);

  const handleClick = () => {
    navigate("/register/discount-questions", { state: state });
  };

  return (
    <>
      <section id="message">
        <div className="mt-6 flex justify-center bg-pink-100 py-3 text-sm font-bold">
          <span>thank you for answering our questions!</span>
        </div>
      </section>
      <section id="bodyText">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className="mb-2 font-sans text-lg font-bold">some reminders:</h3>
          <div className="overflow-hidden rounded-md bg-pink-100 p-4">
            <ul className="mx-3 list-decimal">
              <li>
                This eligibility tool only searches <b>{STATE_LIST[state.us_state]}</b> court
                records.
              </li>
              <li>
                We <b>don't</b> have <b>arrest</b> records.
              </li>
              <li>
                Court data is not perfect. Sometimes certain records won't be found, and this could
                impact your eligibility.
              </li>
            </ul>
          </div>
          <PriceBox usState={state.us_state} isFeeWaiverEligible={isFeeWaiverEligible} />
        </div>
      </section>
      <section id="button">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col justify-center px-4">
          <button
            type="button"
            onClick={handleClick}
            className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
          >
            I understand
          </button>
        </div>
      </section>
    </>
  );
}
