import { ADDITIONAL_CASES, LEGAL_NOTES } from "../../../../constants/surveyTypes";

export const adjustState = (state, stateToSubmit) => {
  const currentAdditionalCases = state.surveys.find((survey) => survey.type === ADDITIONAL_CASES);
  const currentLegalNotes = state.surveys.find((survey) => survey.type === LEGAL_NOTES);
  if (currentAdditionalCases) {
    stateToSubmit.surveys.push(currentAdditionalCases);
  }
  if (currentLegalNotes) {
    stateToSubmit.surveys.push(currentLegalNotes);
  }
};
