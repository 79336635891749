import { container2, header2 } from "../../../constants/classnames";

export const ClioLink = () => {
  const email = localStorage.getItem("email");
  let url = "https://clients.clio.com/login";
  if (email) {
    url += `?email=${email}`;
  }
  return (
    <span className="text-rasapink underline">
      <a target="_blank" rel="noreferrer" href={url}>
        Clio for Clients
      </a>
    </span>
  );
};

export const ClioPage = () => {
  return (
    <div className={container2}>
      <h2 className={header2}>clio for clients</h2>
      <div>
        <div className="my-2">
          Rasa uses Clio Legal Management Software to organize legal work and keep your legal
          documents secure.
        </div>
        <div className="mb-2">
          You can login to <ClioLink /> using the same email you used on this Rasa website and you
          will be sent a secure link to access your Clio account.
        </div>
        <div>
          Once you are logged in, you can view messages from the Rasa legal team. Clio is also where
          we will put your final legal documents when your cases are complete so you can download
          them for your own records.
        </div>
      </div>
    </div>
  );
};
