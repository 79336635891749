import { UT } from "../../../constants/usStates";

export const getPriceSentence = (usState, isFeeWaiverEligible) => {
  if (usState === UT && !isFeeWaiverEligible) {
    return <span className="whitespace-nowrap font-bold">$500 per case!</span>;
  } else if (usState === UT) {
    return <span className="whitespace-nowrap font-bold">$250* per case!</span>;
  } else {
    return <span className="whitespace-nowrap font-bold">$250 per case!</span>;
  }
};

export const PriceBox = ({ usState, isFeeWaiverEligible, isGreen = true }) => {
  let wrapper = "rounded px-4 py-2 font-semibold";
  let disclaimer = "-mt-2 text-xs text-gray-600";
  if (isGreen) {
    wrapper = "rounded bg-rasa-green-100 px-4 py-2 font-semibold";
    disclaimer = "text-xs text-gray-600";
  }
  return (
    <div className="mx-2 mt-4 text-center text-sm leading-5">
      <div className={wrapper}>
        <div>
          If you would like to hire Rasa as your lawyer, our fees start at just{" "}
          {getPriceSentence(usState, isFeeWaiverEligible)}
        </div>
      </div>
      {usState === UT && isFeeWaiverEligible && (
        <div className={disclaimer}>*based on government fee waiver eligibility</div>
      )}
    </div>
  );
};
